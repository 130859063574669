import * as React from "react";
import * as signalR from "@microsoft/signalr";
import store from "../../configureStore";
import axios, { AxiosResponse } from "axios";
import { getWebSocketGroup } from "../../helper/HelperFunctions";
import isEqual from "react-fast-compare";
import { IUserProfile } from "../../core/viewModels/profile/ProfileViewModel";
import {
    INotificationMessage,
    IUserAutoLogoutNotificationMessage,
    LogoutCause,
    NotificationType,
} from "../../core/viewModels/signalR/SignalRModel";
import { initialPagination, MyDownloadsModalConstants } from "../../helper/Constants";
import { FolderType } from "../../core/common/enums";
import { IMessageResponseModel } from "../../core/viewModels/message/MessageResponseModel";
import { IDraftMessageModel } from "../../core/viewModels/message/DraftMailMessage";

const bearerPrefix = "Bearer ";

export interface ISignalRState {
    apiBaseUrl: any;
}

interface SignalRProps {
    profile: IUserProfile;
    showAutoLogoutModal: any;
    getWebSocketConnectionUrl: () => void;
    fetchDraftMessages: (
        pageNumber: number,
        pageSize: number,
        searchString: string,
        callback: (result: IMessageResponseModel<IDraftMessageModel>) => void
    ) => void;
    getDraftMessageDetail: (messageId: number) => void;
    requestMyDownloadsList: (pageNumber: number, pageSize: number) => void;
    fetchMessages: (folderId: number, pageNumber: number, pageSize: number, searchString: string) => void;
    fetchSentMessages: (folderId: number, pageNumber: number, pageSize: number, searchString: string) => void;
    fetchSpamMessages: (folderId: number, pageNumber: number, pageSize: number, searchString: string) => void;
    userPrivilegesChanged(logoutCause: LogoutCause): void;
    archiveFolderReload: () => void;
    refreshSharedFolderRecipient: () => void;
}

export class SignalRWebSocket extends React.Component<SignalRProps, ISignalRState> {
    private user = "";
    private group = "";
    private token = "";
    state = {
        apiBaseUrl: "",
    };

    componentDidMount() {
        this.getToken();
    }
    private getToken() {
        const user = store.getState().userAuth?.user;
        this.token = user?.access_token;
        this.getWebSocketUrl();
    }

    shouldComponentUpdate(nextProps: SignalRProps, nextState: ISignalRState) {
        return (
            (nextProps.profile?.userId !== this.props.profile?.userId && nextProps.profile.userId !== 0) ||
            !isEqual(this.state, nextState)
        );
    }

    private signalRConnectionInit = () => {
        return axios
            .post(`${this.state.apiBaseUrl}/signalr/negotiate`, {
                method: "POST",
                headers: {
                    "x-ms-signalr-userid": this.user,
                    Authorization: bearerPrefix + this.token,
                    ClientType: "SSR-CPA",
                },
            })
            .then((resp: AxiosResponse) => {
                const info = resp.data;
                info.accessToken = info.accessToken || info.accessKey;
                info.url = info.url || info.endpoint;

                const options = {
                    accessTokenFactory: () => info.accessToken,
                };

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(info.url, options)
                    .withAutomaticReconnect()
                    .configureLogging(signalR.LogLevel.Information)
                    .build();
                console.log(connection);
                connection.on("InboxMessageCreated", this.inboxMessageReceived);
                connection.on("SentMessageCreated", this.sentMessageReceived);
                connection.on("DraftMessageDeleted", this.draftMessageDeleted);
                connection.on("SpamMessageCreated", this.spamMessageReceived);
                connection.on("BulkDownload", this.processDownloadedFiles);
                connection.on("UserPrivilegesChanged", this.userPrivilegesChanged);
                connection.on("SharedFolderUpdated", this.refreshFolders);
                connection.on("SharedFolderAccessUpdated", this.refreshSharedFolderRecipient);
                connection.onclose(() => {
                    console.log("disconnected");
                    this.startConnection(connection);
                });
                this.startConnection(connection);
            })
            .catch(reason => {
                console.log(reason);
            });
    };

    private startConnection = (connection: signalR.HubConnection) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _self: any = this;
        console.log("connecting...");
        connection
            .start()
            .then(function () {
                console.log("connected!");
                _self.addGroup();
                connection.invoke("getConnectionId").then(function () {
                    // Send the connectionId to controller
                });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(_self.startConnection, 5000, connection);
            });
    };

    private addGroup = () => {
        return axios
            .post(`${this.state.apiBaseUrl}/signalr/AddToGroup`, {
                recipient: this.user,
                groupname: this.group,
            })
            .then((resp: AxiosResponse) => {
                if (resp.status == 200) {
                    console.log("User added to the group successfully");
                }
            });
    };

    private getWebSocketUrl = () => {
        return axios
            .get(`${process.env.REACT_APP_API_URL}/api/WebSocket/GetWebSocketConnectionUrl`)
            .then((resp: AxiosResponse) => {
                this.setState({ apiBaseUrl: resp.data });
            });
    };

    inboxMessageReceived = (): void => {
        if (store.getState().signalRState.currentPage === FolderType.ARCHIVE) {
            this.props.archiveFolderReload();
        } else if (
            store.getState().signalRState.currentPage == FolderType.INBOX &&
            !this.props.showAutoLogoutModal
        ) {
            this.props.fetchMessages(FolderType.INBOX, 1, initialPagination.pageSize, "");
        }
    };

    sentMessageReceived = () => {
        if (store.getState().signalRState.currentPage === FolderType.ARCHIVE) {
            this.props.archiveFolderReload();
        } else if (
            store.getState().signalRState.currentPage == FolderType.SENT
        ) {
            this.props.fetchSentMessages(FolderType.SENT, 1, initialPagination.pageSize, "");
        }
    };

    spamMessageReceived = () => {
        if (store.getState().signalRState.currentPage === FolderType.ARCHIVE) {
            this.props.archiveFolderReload();
        } else if (
            store.getState().signalRState.currentPage == FolderType.SPAM
        ) {
            this.props.fetchSpamMessages(FolderType.SPAM, 1, initialPagination.pageSize, "");
        }
    };

    draftMessageDeleted = () => {
        this.props.fetchDraftMessages(1, initialPagination.pageSize, "", this.onFetchDraftMessagesComplete);
    };

    onFetchDraftMessagesComplete = () => (result: IMessageResponseModel<IDraftMessageModel>) => {
        if (result && result.messages && result.messages.length > 0) {
            this.props.getDraftMessageDetail(result.messages[0].draftedMessageId);
        }
    };

    processDownloadedFiles = (notificationMessage: INotificationMessage) => {
        switch (notificationMessage.notificationType) {
            case NotificationType.BulkDownloadSuccess:
            case NotificationType.BulkDownloadFailed:
                if (store.getState().signalRState.myDownloadsCurrentPage === 1)
                    this.props.requestMyDownloadsList(1, MyDownloadsModalConstants.PAGE_SIZE);
                break;
        }
    };

    userPrivilegesChanged = (notificationMessage: IUserAutoLogoutNotificationMessage): void => {
        this.props.userPrivilegesChanged(notificationMessage.logoutCause);
    };

    refreshFolders = () => {
        if (store.getState().signalRState.currentPage == FolderType.ARCHIVE) {
            this.props.archiveFolderReload();
        }
    };

    refreshSharedFolderRecipient = () => {
        if (store.getState().signalRState.currentPage == FolderType.ARCHIVE
            && !store.getState().signalRState.isMoveToFolderPopupOpen) {
            this.props.archiveFolderReload();
            this.props.refreshSharedFolderRecipient();
        }
    };

    public render() {
        if (
            this.state.apiBaseUrl != "" &&
            store.getState().userAuth.user.profile.company_id > 0 &&
            store.getState().userAuth.user.profile.user_id !== 0
        ) {
            this.user =
                store.getState().userAuth.user.profile.company_id +
                "_" +
                store.getState().userAuth.user.profile.user_id;
            this.group = getWebSocketGroup(store.getState().userAuth.user.profile.company_id);
            this.signalRConnectionInit();
        }
        return <div />;
    }
}
export default SignalRWebSocket;
