import React, { useEffect, useRef, useState } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable, { ColumnDescription, SelectRowProps, SortOrder, TableChangeHandler } from "react-bootstrap-table-next";
import "./styles.scss";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import {
    initialLockLinkObject,
    ILockLinkModel,
    IOnehubLockedTPViewModel,
    initialLockedOneubuserModel,
} from "../../../core/viewModels/unlock/UnlockModel";
import {
    DateFormatConstants,
    DEFAULT_PAGE_SIZE,
    UnlockLinkConstants,
} from "../../../helper/Constants";
import { getUnlockLinks, updateUnlockLinks } from "../../../actionCreators/unlockActionCreator";
import { ApplicationState } from "../../../store";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getFormattedLocalDateTime } from "../../../helper/DateHelperFunctions";
import { TabType } from "../../../core/common/enums";
import { UnLockIcon } from "../../svg/IconCollection";

interface UnlockProps {
    activeTab: TabType;
}
export const UnlockTable: React.FC<UnlockProps> = ({ activeTab }) => {
    const dispatch = useDispatch();
    const [unlockLinks, setUnlockLinks] = useState<ILockLinkModel[]>([initialLockLinkObject]);
    const [lockedOnehubUsers, setLockedOnehubUsers] = useState<IOnehubLockedTPViewModel[]>([
        initialLockedOneubuserModel,
    ]);
    const [selectedUnlockLinks, setSelectedUnlockLinks] = useState<ILockLinkModel[]>([]);
    const [selectedLockedOnehubUsers, setSelectedLockedOnehubUsers] = useState<IOnehubLockedTPViewModel[]>([]);
    const [pagination, setPagination] = useState({
        pageSize: DEFAULT_PAGE_SIZE,
        currentPage: 1,
        totalRecords: 0,
    });
    const [sortState, setSortState] = useState({
        sortField: "",
        sortOrder: "desc" as SortOrder,
    });
    const [reload, setReload] = useState(false);
    const unlockStoreData = useSelector((state: ApplicationState) => state.unlockState);
    const [totalRecords, setTotalRecords] = useState(0);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const customTotal = (from: number, to: number) => (
        <span
            style={{ display: totalRecords <= pagination.pageSize ? "none" : "block" }}
            className="total-count"
        >
            Showing <b>{from}</b> to <b>{to}</b> of <b>{totalRecords}</b> entries
        </span>
    );

    const tablePaginationOptions = {
        totalSize: pagination.totalRecords,
        sizePerPage: pagination.pageSize,
        page: pagination.currentPage,
        onPageChange: (page: number) => handleOnPageChange(page),
        paginationTotalRenderer: customTotal,
        showTotal: true,
        withFirstAndLast: true,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
    };

    const isAnySelected =
        activeTab === TabType.Messages || activeTab === TabType.Requests
            ? selectedUnlockLinks.length > 0
            : selectedLockedOnehubUsers.length > 0;
    const prevActiveTab = useRef(activeTab);
    useEffect(() => {
        dispatch(getUnlockLinks(activeTab));
    }, [activeTab, reload]);
    
    useEffect(() => {
        if (unlockStoreData.unlockLinks) {
            if (activeTab === TabType.Messages || activeTab === TabType.Requests) {
                setUnlockLinks(unlockStoreData.unlockLinks as ILockLinkModel[]);
                setPagination({
                    ...pagination,
                    totalRecords: unlockStoreData.unlockLinks.length,
                });
                setTotalRecords(unlockStoreData.unlockLinks.length);
            } else if (activeTab === TabType.Onehub) {
                setLockedOnehubUsers(unlockStoreData.unlockLinks as IOnehubLockedTPViewModel[]);
                setPagination({
                    ...pagination,
                    totalRecords: unlockStoreData.unlockLinks.length,
                });
                setTotalRecords(unlockStoreData.unlockLinks.length);
            }    
            if (activeTab !== prevActiveTab.current) {
                handleOnPageChange(1);
                setSelectedUnlockLinks([]);
                setSelectedLockedOnehubUsers([]);            }
            prevActiveTab.current = activeTab;
        }
    }, [unlockStoreData, activeTab]);

    useEffect(() => {
        setSortState({ sortField: "", sortOrder: "desc" });
    }, [prevActiveTab.current]);
    
    const emailAddressFormatter = (cell: string): JSX.Element => {
        return (
            <span className="ellipsis email-cell" title={cell}>
                {cell}
            </span>
        );
    };

    const subjectFormatter = (cell: string): JSX.Element => {
        return (
            <span className="ellipsis subject-cell" title={cell}>
                {cell}
            </span>
        );
    };

    const sentDateFormatter = (cell: string, row: ILockLinkModel): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span className="ellipsis date-cell">
                    {getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}
                </span>
                {unlockFormatter(row)}
            </div>
        );
    };

    const lockedDateFormatter = (cell: string, row: IOnehubLockedTPViewModel): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span className="ellipsis date-cell">
                    {getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}
                </span>
                {unlockFormatter(row)}
            </div>
        );
    };

    const unlockFormatter = (row: ILockLinkModel | IOnehubLockedTPViewModel): JSX.Element => {
        return (
            <div className="inline-actions-bar-container">
                <button
                    className={"button-secondary-blue-outlined"}
                    style={activeTab === TabType.Onehub ? { marginRight: "-22px" } : { marginRight: "-4px" }}
                    onClick={e => {
                        e.stopPropagation();
                        handleInlineUnlockClick(row);
                    }}
                >
                    {"Unlock"}
                </button>
            </div>
        );
    };

    const handleInlineUnlockClick = (row: ILockLinkModel | IOnehubLockedTPViewModel) => {
        if (activeTab === TabType.Messages || activeTab === TabType.Requests) {
            setSelectedUnlockLinks([row as ILockLinkModel]);
            handleUnlockClick([row as ILockLinkModel]);
        } else if (activeTab === TabType.Onehub) {
            setSelectedLockedOnehubUsers([row as IOnehubLockedTPViewModel]);
            handleOnehubUnlockClick([row as IOnehubLockedTPViewModel]);
        }
    };

    const HeaderActionsBar = (): JSX.Element => {
        const handleHeaderActionsBarClick = () => {
            if (activeTab === TabType.Messages || activeTab === TabType.Requests) {
                handleUnlockClick([]);
            } else if (activeTab === TabType.Onehub) {
                handleOnehubUnlockClick([]);
            }
        };
        return (
            <div className="header-toolbar-action drop-off-header">
                <span
                    onClick={() => isAnySelected && handleHeaderActionsBarClick()}
                    title={UnlockLinkConstants.UNLOCK_BUTTON_HOVER_TEXT}
                    className={isAnySelected ? "pointer" : "isDisabled"}
                >
                    <UnLockIcon width={18} height={18} disabled={!isAnySelected} />
                </span>
            </div>
        );
    };

    const columnsForMessagesOrRequests: ColumnDescription[] = [
        {
            text: "Email Address",
            dataField: "emailAddress",
            sort: true,
            formatter: emailAddressFormatter,
        },
        {
            text: "Subject",
            dataField: "subject",
            sort: true,
            formatter: subjectFormatter,
        },
        {
            text: "Date Sent",
            dataField: "dateSent",
            sort: true,
            formatter: sentDateFormatter,
        },
        {
            text: "",
            dataField: "unlock",
            headerFormatter: HeaderActionsBar,
        },
    ];

    const columnsForOneHub: ColumnDescription[] = [
        {
            text: "Email Address",
            dataField: "tpEmail",
            sort: true,
            formatter: emailAddressFormatter,
        },
        {
            text: "Date Locked",
            dataField: "lockedOn",
            sort: true,
            formatter: lockedDateFormatter,
        },
        {
            text: "",
            dataField: "unlock",
            headerFormatter: HeaderActionsBar,
        },
    ];

    const columns = activeTab === TabType.Onehub ? columnsForOneHub : columnsForMessagesOrRequests;

    const setNoContent = () => {
        if (!unlockStoreData.isLoading && (
            (activeTab === TabType.Onehub && lockedOnehubUsers.length === 0) ||
            ((activeTab === TabType.Messages || activeTab === TabType.Requests) && unlockLinks.length === 0)
        )) {
            return UnlockLinkConstants.NO_DATA_TO_DISPLAY;
        }
    };

    const onTableChange: TableChangeHandler<any> | undefined = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            handleSort(sortField, sortOrder);
        }
        setPagination(prev => ({ ...prev, currentPage: page }));
    };

    const handleSort = (field: string, order: "asc" | "desc") => {
        setSortState({
            sortField: field,
            sortOrder: order,
        });
        const sortedData = sortData([... (activeTab === TabType.Onehub ? lockedOnehubUsers : unlockLinks)], field, order);
        if (activeTab === TabType.Onehub) {
            setLockedOnehubUsers(sortedData as IOnehubLockedTPViewModel[]);
        } else {
            setUnlockLinks(sortedData as ILockLinkModel[]);
        }
    };

    const sortData = (data: any[], field: string, order: "asc" | "desc") => {
        return data.sort((a, b) => {
            const aValue = a[field].toString().toLowerCase(); 
            const bValue = b[field].toString().toLowerCase();
    
            if (order === "asc") {
                return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
            } else {
                return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
            }
        });
    };


    const handleOnSelect = (row: ILockLinkModel | IOnehubLockedTPViewModel, isSelect: boolean) => {
        if (activeTab === TabType.Messages || activeTab === TabType.Requests) {
            const updatedSelectedLinks = isSelect
                ? [...selectedUnlockLinks, row as ILockLinkModel]
                : selectedUnlockLinks.filter(
                    link => link.sentMessageRecipientId !== (row as ILockLinkModel).sentMessageRecipientId
                );
            setSelectedUnlockLinks(updatedSelectedLinks);
        } else if (activeTab === TabType.Onehub) {
            const updatedSelectedUsers = isSelect
                ? [...selectedLockedOnehubUsers, row as IOnehubLockedTPViewModel]
                : selectedLockedOnehubUsers.filter(user => user.tpEmail !== (row as IOnehubLockedTPViewModel).tpEmail);
            setSelectedLockedOnehubUsers(updatedSelectedUsers);
        }
    };

    const handleOnSelectAll = (isSelect: boolean, rows: ILockLinkModel[] | IOnehubLockedTPViewModel[]) => {
        if (isSelect) {
            if (activeTab === TabType.Messages || activeTab === TabType.Requests) {
                setSelectedUnlockLinks(rows as ILockLinkModel[]);
            } else if (activeTab === TabType.Onehub) {
                setSelectedLockedOnehubUsers(rows as IOnehubLockedTPViewModel[]);
            }
        } else {
            setSelectedUnlockLinks([]);
            setSelectedLockedOnehubUsers([]);
        }
    };

    const handleUnlockClick = (rows?: ILockLinkModel[]) => {
        const payload = rows && rows.length > 0 ? rows : selectedUnlockLinks;
        dispatch(
            updateUnlockLinks(payload, activeTab, (result: boolean) => {
                if (result) {
                    const currentPageRecords = unlockLinks.slice(
                        (pagination.currentPage - 1) * pagination.pageSize,
                        pagination.currentPage * pagination.pageSize
                    );
                    setUnlockLinks(prev => prev.filter(link => !payload.some(p => p.sentMessageRecipientId === link.sentMessageRecipientId)));
                    if (currentPageRecords.length === payload.length  && pagination.currentPage > 1) {
                        handleOnPageChange(pagination.currentPage - 1);
                    }
                    setSelectedUnlockLinks([]);
                    setReload(prev => !prev);
                }
            })
        );
    };

    const handleOnehubUnlockClick = (rows?: IOnehubLockedTPViewModel[]) => {
        const payload = rows && rows.length > 0 ? rows : selectedLockedOnehubUsers;
        dispatch(
            updateUnlockLinks(payload, activeTab, (result: boolean) => {
                if (result) {
                    setLockedOnehubUsers(prev => prev.filter(user => !payload.some(p => p.tpEmail === user.tpEmail)));
                    if (selectedLockedOnehubUsers.length === pagination.pageSize && pagination.currentPage > 1) {
                        setPagination(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
                    }
                    setSelectedLockedOnehubUsers([]);
                    setReload(prev => !prev);
                }
            })
        );
    };

    const handleOnPageChange = async (pageNo: number) => {
        setPagination({
            ...pagination,
            currentPage: pageNo,
        });
    };

    const selectRow: SelectRowProps<any> = {
        mode: "checkbox",
        clickToSelect: true,
        selected:
            activeTab === TabType.Messages || activeTab === TabType.Requests
                ? selectedUnlockLinks.map(link => link.sentMessageRecipientId)
                : selectedLockedOnehubUsers.map(user => user.tpEmail),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!selectedUnlockLinks.length || !!selectedLockedOnehubUsers.length}
                indeterminate={indeterminate}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text={""}
            />
        ),
    };

    return (
        <LoadingOverlay className="unlock-loader">
          
            <div className="unlock-parent-container">
                <div className="unlock-tab-content">
                    <header>
                        {(() => {
                            switch (activeTab) {
                                case TabType.Messages:
                                    return <span>{UnlockLinkConstants.UNLOCK_MESSAGE_HEADER_HELPTEXT}</span>;
                                case TabType.Requests:
                                    return <span>{UnlockLinkConstants.UNLOCK_REQUEST_HEADER_HELPTEXT}</span>;
                                case TabType.Onehub:
                                    return <span>{UnlockLinkConstants.UNLOCK_ONEHUB_HEADER_HELPTEXT}</span>;
                                default:
                                    return null;
                            }
                        })()}
                    </header>
                </div>
                <div className={`unlock-table-container ${mailboxUserData ? "unlock-delegate-container":""}`}>
                    <BootstrapTable
                        classes="settings-table"
                        key={`${prevActiveTab.current}-${pagination.currentPage}-${totalRecords}`}
                        keyField={
                            activeTab === TabType.Messages || activeTab === TabType.Requests
                                ? "sentMessageRecipientId"
                                : "tpEmail"
                        }
                        columns={columns}
                        data={
                            activeTab === TabType.Messages || activeTab === TabType.Requests
                                ? unlockLinks
                                : lockedOnehubUsers
                        }
                        onTableChange={onTableChange}
                        selectRow={selectRow}
                        noDataIndication={setNoContent()}
                        pagination={paginationFactory(tablePaginationOptions)}
                        bordered={false}
                        remote ={{sort : true}}
                        sort={{ dataField: sortState.sortField, order: sortState.sortOrder }}
                    />
                </div>
            </div>
            <Loader loading={unlockStoreData.isLoading} />
        </LoadingOverlay>
    );
};
