import { ReportsFilterConstants } from "../../../helper/Constants";
import { DateType, DocumentRequestStatusHistoryReport, FilterType, LinkStatus, MethodOfTransfer } from "../../common/enums";

export interface IFileActivity {
    EmailAddress: string;
    FileName: string;
    AccessDate: string;
    IPAddress: string;
}
export interface IFileActivityDR extends IFileActivity {
    Document: string;
}
export interface IActivityLogData {
    email: string;
    accessDate: string;
    filesUploaded: number;
    filesDownloaded: number;
}
export interface IItem {
    id: number;
    name: string;
    key: string;
    required?: boolean;
}

export interface IDateObject {
    dateType: DateType;
    fromDate: Date;
    toDate: Date;
}

export interface ISenderList {
    email: string;
    name: string;
}

export interface IReportSenderModel {
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    userId: number;
}
export interface IFilterCondition {
    emailAddress: string[];
    linkStatus: LinkStatus[];
    transferMode: MethodOfTransfer[];
    sentDate: IDateObject;
    activityLog: IDateObject;
    requestStatus: DocumentRequestStatusHistoryReport[];
}
export interface IFilterModel {
    id?: number;
    name: string;
    filterSource: FilterType;
    userId: number;
    isSelected: boolean;
    filterCondition: IFilterCondition;
}

export interface IDateValidationObject {
    sentDate: {
        fromDate: boolean;
        toDate: boolean;
    };
    activityLog: {
        fromDate: boolean;
        toDate: boolean;
    };
}

//Document-file mapping
export interface IUploadedFile {
    document: string;
    fileName: string;
    uploadedDate: Date;
}
export const initialDateValidationObject = {
    sentDate: {
        fromDate: false,
        toDate: false,
    },
    activityLog: {
        fromDate: false,
        toDate: false,
    },
};

export const initialFilterValues: IFilterModel = {
    id: -1, // Initial filter id (if sent as -1 backend will check for selected filter and will send res accordingly)
    name: "",
    filterSource: FilterType.SIH,
    userId: 0,
    isSelected: false,
    filterCondition: {
        emailAddress: [],
        linkStatus: [LinkStatus.Active, LinkStatus.Cancelled, LinkStatus.Expired],
        activityLog: {
            dateType: DateType.All,
            fromDate: new Date(ReportsFilterConstants.REPORT_MIN_DATE),
            toDate: new Date(),
        },
        transferMode: [
            MethodOfTransfer.PortalAccessCode,
            MethodOfTransfer.PortalQA,
            MethodOfTransfer.WebAddInAccessCode,
            MethodOfTransfer.WebAddInQA,
        ],
        sentDate: {
            dateType: DateType.All,
            fromDate: new Date(ReportsFilterConstants.REPORT_MIN_DATE),
            toDate: new Date(),
        },
        requestStatus: [DocumentRequestStatusHistoryReport.PendingUpload, DocumentRequestStatusHistoryReport.PartiallyUploaded,  DocumentRequestStatusHistoryReport.Overdue, DocumentRequestStatusHistoryReport.Completed, DocumentRequestStatusHistoryReport.Expired, DocumentRequestStatusHistoryReport.Canceled]
    },
};

export const activityLogTableColumns = ["Email", "FileName", "Date", "IP Address"];

export const activityLogTableColumnsDR = ["Email", "Document", "FileName", "Date", "IP Address"];

export const uploadedFilesTableColumns = ["Document", "FileName", "Uploaded Date"];
export interface IMetricsCardModel {
    title: string;
    count: number;
}
