import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import Dropdown, { Option } from "react-dropdown";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ColorConstants } from "../../../../assets/custom/colors";
import { ReportsFilterConstants, ValidationContants } from "../../../../helper/Constants";
import { getCurrentDate, getMessageFormattedDate } from "../../../../helper/DateHelperFunctions";
import {
    IDateObject,
    IDateValidationObject,
    IFilterCondition,
    IFilterModel,
    ISenderList,
} from "../../../../core/viewModels/report/reportViewModel";
import { isAdminUser, checkInputIsBlank } from "../../../../helper/HelperFunctions";
import Checkbox, { CheckBoxSize } from "../../../common/ui/Checkbox";
import CustomDatePicker from "../../../common/ui/CustomDatePicker";
import { CustomModal } from "../../../common/ui/Modals/CustomModal";
import SearchInput from "../../../common/ui/SearchInput";
import { CloseIcon, DeleteIcon, ResetFilterIcon } from "../../../svg/IconCollection";
import { DateType, DocumentRequestStatusHistoryReport, FilterType, LinkStatus, MethodOfTransfer } from "../../../../core/common/enums";
import { getDateTypeNames, getLinkStatusName, getRequestStatusName, getTransferModeName } from "./Formatter";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { logger } from "../../../../oidcClient/authProvider";
import { appInsightsData } from "../../../../helper/AppInsightsData";
import { AutomationIdConstants } from "../../../../helper/AutomationConstants";

interface IFilterModalState {
    linkStatusItems: LinkStatus[];
    dateItems: DateType[];
    sendersListItems: ISenderList[];
    transferModeItems: MethodOfTransfer[];
    requestStatusItems: DocumentRequestStatusHistoryReport[];
}

const initialFilterState: IFilterModalState = {
    dateItems: [DateType.All, DateType.Last30Days, DateType.Last12Months, DateType.Custom],
    linkStatusItems: [LinkStatus.Active, LinkStatus.Expired, LinkStatus.Cancelled],
    requestStatusItems: [DocumentRequestStatusHistoryReport.PendingUpload, DocumentRequestStatusHistoryReport.PartiallyUploaded,  DocumentRequestStatusHistoryReport.Overdue, DocumentRequestStatusHistoryReport.Completed, DocumentRequestStatusHistoryReport.Expired, DocumentRequestStatusHistoryReport.Canceled],
    sendersListItems: [],
    transferModeItems: [
        MethodOfTransfer.PortalAccessCode,
        MethodOfTransfer.PortalQA,
        MethodOfTransfer.WebAddInAccessCode,
        MethodOfTransfer.WebAddInQA,
    ],
};

interface IFilterProps {
    showSaveFilterModal: boolean;
    filterType: FilterType;
    sendersList: ISenderList[];
    filters: {
        isLoading: boolean;
        data: IFilterModel[];
    };
    selectedFilter: IFilterModel;
    dateValidation: IDateValidationObject;
    sentDateAccordionValidation: boolean;
    activityDateAccordionValidation: boolean;
    invalidSentDateValidation: boolean;
    setInvalidSentDateValidation: React.Dispatch<React.SetStateAction<boolean>>;
    invalidActivityDateValidation: boolean;
    setInvalidActivityDateValidation: React.Dispatch<React.SetStateAction<boolean>>;
    setEnableClearFilter: React.Dispatch<React.SetStateAction<boolean>>;
    resetFilterEnable: boolean;
    setResetFilterEnable: React.Dispatch<React.SetStateAction<boolean>>;
    setActivityDateAccordionValidation: React.Dispatch<React.SetStateAction<boolean>>;
    setSentDateAccordionValidation: React.Dispatch<React.SetStateAction<boolean>>;
    handleApplyFilter: () => void;
    handleDeleteFilter: (id: number) => void;
    handleCreateFilter: (name: string, isApplied?: boolean) => void;
    handleClearDropdownFilter: () => void;
    setSelectedFilter: React.Dispatch<React.SetStateAction<IFilterModel>>;
    clearFilterConditions: () => void;
    hideSaveFilterModal: () => void;
    handleFilterStateChange: (data: IFilterCondition) => void;
    toggleDateValidation: React.Dispatch<React.SetStateAction<IDateValidationObject>>;
}

export const Filters: FC<IFilterProps> = props => {
    const {
        showSaveFilterModal,
        filters,
        filterType,
        selectedFilter,
        dateValidation,
        sentDateAccordionValidation,
        activityDateAccordionValidation,
        invalidSentDateValidation,
        setInvalidSentDateValidation,
        invalidActivityDateValidation,
        setInvalidActivityDateValidation,
        setEnableClearFilter,
        resetFilterEnable,
        setResetFilterEnable,
        toggleDateValidation,
        setSelectedFilter,
        clearFilterConditions,
        handleFilterStateChange,
        setSentDateAccordionValidation,
        handleCreateFilter,
        hideSaveFilterModal,
        setActivityDateAccordionValidation,
    } = props;
    const filterCondition = selectedFilter.filterCondition;
    const [filterDropdownValue, setFilterDropdownValue] = useState("");
    const [senderSearchValue, setSenderSearchValue] = useState("");
    const [filterData, setFilterData] = useState<IFilterModalState>(initialFilterState);
    const [sendersList, setSendersList] = useState<ISenderList[]>(filterData.sendersListItems);
    const [filterTextInput, setFilterTextInput] = useState("");
    const [showFilterTextInputValidation, setFilterTextInputValidation] = useState(false);
    const [showDuplicateFilterValidation, setShowDuplicateFilterValidation] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const showRequiredFieldWarning = sentDateAccordionValidation || activityDateAccordionValidation;
    const showInvalidDateWarning = invalidSentDateValidation || invalidActivityDateValidation;
    const [filterDropdownOptions, setFilterDropdownOptions] = useState<Option[]>();    
    const messageHistorySettings = useSelector(
        (state: ApplicationState) => state.adminSettingsState.messageHistorySettings
    );
    const user = useSelector((state: ApplicationState) => state.userAuth.user);
    const settings =
        filterType === FilterType.SIH || filterType === FilterType.DRH
            ? messageHistorySettings.sentItemsSettings
            : messageHistorySettings.receivedItemsSettings;
    const showSenders = !isAdminUser(user) ? settings?.isUserAllowedToViewTransferOfAllUsers : true;
    const clearFromDatePicker = React.useRef(null);
    const clearToDatePicker = React.useRef(null);

    useEffect(() => {
        setFilterData({
            ...filterData,
            sendersListItems: props.sendersList,
            transferModeItems:
                filterType === FilterType.RIH
                    ? [MethodOfTransfer.DropOff, MethodOfTransfer.FolderDropOff, ...initialFilterState.transferModeItems]
                    : initialFilterState.transferModeItems,
        });
        setFilterDropdownValue(selectedFilter.name);
        // sender list to the default values should not be set until search is empty
        if (!senderSearchValue) {
            setSendersList(props.sendersList);
        }
    }, [selectedFilter, props.sendersList?.length]);

    useEffect(() => {
        if (filters?.data.length > 0) {
            const filterOptions: Option[] = [];
            filters.data.map((item: IFilterModel) => {
                filterOptions.push({
                    label: item.name,
                    value: item.name,
                    data: { "test-auto": AutomationIdConstants.commonSihRih.FilterDropDownFilterOptions },
                });
            });
            setFilterDropdownOptions(filterOptions);
        } else {
            setFilterDropdownOptions([]);
        }
    }, [filters]);

    const getIndeterminateValue = () =>
        !sendersList.every((item: ISenderList) => filterCondition.emailAddress?.includes(item.email));

    const getCheckedValue = () =>
        filterCondition.emailAddress?.some((email: string) =>
            sendersList.find(item => item.email === email)
        );

    const handleOnClickDelete = () => {
        filterDropdownValue && setShowDeleteModal(true);
    };

    const getPageTitle = () =>
        FilterType.SIH === filterType
            ? appInsightsData.MessageHistory.SentItems.PageTitle
            : appInsightsData.MessageHistory.ReceivedItems.PageTitle;

    const handleFilterDropdownChange = (option: Option) => {
        const filter = filters.data && filters.data.find((item: IFilterModel) => item.name === option.value);
        filter && setSelectedFilter(filter);
        setFilterDropdownValue(option.value);
        setEnableClearFilter(true);
        setResetFilterEnable(false);
        logger.trackEvent({
            name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.SavedFilterSelected}`,
            properties: {
                page: getPageTitle(),
                value: option.value,
            },
        });
    };

    const handleSenderSearchChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = e.target.value;
        setSenderSearchValue(value);
        const filteredList = filterData.sendersListItems.filter(data =>
            data.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        );
        setSendersList(filteredList);
    };

    const handleSendersSelection: React.ChangeEventHandler<HTMLInputElement> = e => {
        let updatedList = [...filterCondition.emailAddress];
        if (e.target.checked) {
            updatedList = [...filterCondition.emailAddress, e.target.value];
        } else {
            updatedList.splice(filterCondition.emailAddress.indexOf(e.target.value), 1);
        }
        handleFilterStateChange({
            ...filterCondition,
            emailAddress: updatedList,
        });
        logger.trackEvent({
            name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.FilterSenderListUpdated}`,
            properties: {
                page: getPageTitle(),
                emailIdList: updatedList,
            },
        });
    };

    const selectAllSenders = (e: Event) => {
        const allEmails = sendersList;        
        const remainingEmails = filterCondition.emailAddress.filter((email: string) => !allEmails.find(item => item.email === email));
        const combinedEmails = [
            ...allEmails.map((item: ISenderList) => item.email),
            ...remainingEmails
          ];

        (e.target as HTMLInputElement).checked ? 
            handleFilterStateChange({
                ...filterCondition,
                emailAddress: combinedEmails,
            })
        : 
        handleFilterStateChange({
            ...filterCondition,
            emailAddress: remainingEmails,
        });
    };
 

    const handleLinkStatusSelection: React.ChangeEventHandler<HTMLInputElement> = e => {
        let updatedList = [...filterCondition.linkStatus];
        if (e.target.checked) {
            updatedList = [...updatedList, parseInt(e.target.value)];
        } else {
            updatedList.splice(filterCondition.linkStatus.indexOf(parseInt(e.target.value)), 1);
        }
        handleFilterStateChange({
            ...filterCondition,
            linkStatus: updatedList,
        });
        logger.trackEvent({
            name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.FilterLinkStatusUpdated}`,
            properties: {
                page: getPageTitle(),
                value: updatedList,
            },
        });
    };

    const handleRequestStatusSelection: React.ChangeEventHandler<HTMLInputElement> = e => {        
        if(filterCondition?.requestStatus){
            let updatedList = [...filterCondition.requestStatus];
            if (e.target.checked) {
                updatedList = [...updatedList, parseInt(e.target.value)];
            } else {
                updatedList.splice(filterCondition.requestStatus.indexOf(parseInt(e.target.value)), 1);
            }
            handleFilterStateChange({
                ...filterCondition,
                requestStatus: updatedList,
            });
            
            logger.trackEvent({
                name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.FilterRequestStatusUpdated}`,
                properties: {
                    page: getPageTitle(),
                    value: updatedList,
                },
            });
        }
    };

    const handleTransferModeSelection: React.ChangeEventHandler<HTMLInputElement> = e => {
        let updatedList = [...filterCondition.transferMode];
        if (e.target.checked) {
            updatedList = [...updatedList, parseInt(e.target.value)];
        } else {
            updatedList.splice(updatedList.indexOf(parseInt(e.target.value)), 1);
        }
        handleFilterStateChange({
            ...filterCondition,
            transferMode: updatedList,
        });
        logger.trackEvent({
            name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.FilterTransferModeUpdated}`,
            properties: {
                page: getPageTitle(),
                value: updatedList,
            },
        });
    };

    const handleDateRadioButtonChange = (value: DateType, dateType: string) => {
        logger.trackEvent({
            name: `${getPageTitle()} - ${
                dateType === "activityLog"
                    ? appInsightsData.MessageHistory.Operation.FilterActivityLogDateUpdated
                    : appInsightsData.MessageHistory.Operation.FilterDateUpdated
            }`,
            properties: {
                page: getPageTitle(),
                dateType: value,
            },
        });
        switch (value) {
            case DateType.All:
                handleFilterStateChange({
                    ...filterCondition,
                    [dateType]: {
                        dateType: DateType.All,
                        fromDate: moment().subtract(10, "years").toDate(),
                        toDate: moment().toDate(),
                    },
                });
                toggleDateValidation({
                    ...dateValidation,
                    [dateType]: {
                        fromDate: false,
                        toDate: false,
                    },
                });
                break;
            case DateType.Last30Days:
                handleFilterStateChange({
                    ...filterCondition,
                    [dateType]: {
                        dateType: DateType.Last30Days,
                        fromDate: moment().subtract(30, "days").toDate(),
                        toDate: moment().toDate(),
                    },
                });
                toggleDateValidation({
                    ...dateValidation,
                    [dateType]: {
                        fromDate: false,
                        toDate: false,
                    },
                });
                break;
            case DateType.Last12Months:
                handleFilterStateChange({
                    ...filterCondition,
                    [dateType]: {
                        dateType: DateType.Last12Months,
                        fromDate: moment().subtract(12, "months").toDate(),
                        toDate: moment().toDate(),
                    },
                });
                toggleDateValidation({
                    ...dateValidation,
                    [dateType]: {
                        fromDate: false,
                        toDate: false,
                    },
                });
                break;
            case DateType.Custom:
                const datePayload = dateType === "sentDate" ? filterCondition.sentDate : filterCondition.activityLog;
                handleFilterStateChange({
                    ...filterCondition,
                    [dateType]: {
                        ...datePayload,
                        dateType: DateType.Custom,
                        fromDate: undefined,
                        toDate: undefined,
                    },
                });
                break;
        }
    };

    const removeHighlightedBorder = (filterType: string) => {
        if(filterType === ReportsFilterConstants.SENT_DATE) {
            setSentDateAccordionValidation(false);
            setInvalidSentDateValidation(false);
        } 

        if(filterType === ReportsFilterConstants.ACTIVITY_LOG) {
            setActivityDateAccordionValidation(false);
            setInvalidActivityDateValidation(false);
        } 
    };

    const handleRadioButtonChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        removeHighlightedBorder(e.target.name);
        handleDateRadioButtonChange(parseInt(e.target.value), e.target.name);
    };

    const handleSentDateChange = (dateType: string, FilterType: string, date?: Date | undefined) => {
        // Handle date change
        date = moment(date).toDate();

        // Remove accordion warning border when date is changed
        removeHighlightedBorder(FilterType);

        // Date validations
        let isValid = true;
        const dateObject = filterCondition[FilterType as keyof IDateValidationObject];
        if (dateType === "fromDate") {
            isValid = moment(date).isSameOrBefore(dateObject.toDate);
        }
        if (dateType === "toDate") {
            if (dateObject.fromDate) {
                isValid = moment(date).isSameOrAfter(dateObject.fromDate);
            }
        }

        // Date and Date validation state update code based on above result
        if (!isValid || date.toDateString() === "Invalid Date" || date.getFullYear() === 0) {
            if(FilterType?.toLowerCase() == "sentdate") {
                setInvalidSentDateValidation(true);
                setSentDateAccordionValidation(false);
            }
            else if(FilterType?.toLowerCase() == "activitylog") {
                setInvalidActivityDateValidation(true);
                setActivityDateAccordionValidation(false);
            }
            
            toggleDateValidation({
                ...dateValidation,
                [FilterType]: {
                    ...dateValidation[FilterType as keyof IDateValidationObject],
                    [dateType]: true,
                },
            });
            dateType === "fromDate"
                ? clearFromDatePicker && clearFromDatePicker.current
                : clearToDatePicker && clearToDatePicker.current;
        } else {
            if(FilterType?.toLowerCase() == "sentdate") {
                setInvalidSentDateValidation(false);
            }
            else if(FilterType?.toLowerCase() == "activitylog") {
                setInvalidActivityDateValidation(false);
            }

            toggleDateValidation({
                ...dateValidation,
                [FilterType]: {
                    ...dateValidation[FilterType as keyof IDateValidationObject],
                    [dateType]: false,
                },
            });
        }

        handleFilterStateChange({
            ...filterCondition,
            [FilterType]: {
                ...filterCondition[FilterType as keyof IFilterCondition],
                [dateType]: !isValid ? undefined : date,
            },
        });
        if (dateType === "fromDate") {
            logger.trackEvent({
                name: `${getPageTitle()} - ${
                    FilterType === "sentDate"
                        ? appInsightsData.MessageHistory.Operation.FilterCustomMessageFromDateUpdated
                        : appInsightsData.MessageHistory.Operation.FilterCustomActivityLogFromDateUpdated
                }`,
                properties: {
                    page: getPageTitle(),
                    fromDate: date,
                },
            });
        } else {
            logger.trackEvent({
                name: `${getPageTitle()} - ${
                    FilterType === "sentDate"
                        ? appInsightsData.MessageHistory.Operation.FilterCustomMessageToDateUpdated
                        : appInsightsData.MessageHistory.Operation.FilterCustomActivityLogToDateUpdated
                }`,
                properties: {
                    page: getPageTitle(),
                    toDate: date,
                },
            });
        }
    };

    const handleClearDateFilter = (dateType: string, FilterType: string, date?: Date | undefined) => {
        if (!date) {
            handleFilterStateChange({
                ...filterCondition,
                [FilterType]: {
                    ...filterCondition[FilterType as keyof IFilterCondition],
                    [dateType]: date,
                },
            });
            toggleDateValidation({
                ...dateValidation,
                [FilterType]: {
                    ...dateValidation[FilterType as keyof IDateValidationObject],
                    [dateType]: false,
                },
            });

            if(FilterType === ReportsFilterConstants.SENT_DATE) {
                setSentDateAccordionValidation(true);
                setInvalidSentDateValidation(false);
            } 
    
            if(FilterType === ReportsFilterConstants.ACTIVITY_LOG) {
                setActivityDateAccordionValidation(true);
                setInvalidActivityDateValidation(false);
            }
        }
    };

    const handleClearDropDownFilter = () => {
        setFilterDropdownValue("");
        props.handleClearDropdownFilter();
    };

    const checkSelectedDateType = (type: string, item: DateType) => {
        switch (type) {
            case "sentDate":
                if (filterCondition.sentDate.dateType === item) return true;
                break;
            case "activityLog":
                if (filterCondition.activityLog?.dateType === item) return true;
                break;
            default:
                return false;
        }
    };

    const getDateValue = (filterType: string, type: string, item: DateType) => {
        const dateObject = filterCondition[filterType as keyof IDateValidationObject];
        if (checkSelectedDateType(filterType, item) && dateObject[type as keyof IDateObject]) {
            switch (type) {
                case "fromDate":
                    return getMessageFormattedDate(dateObject.fromDate).toString();
                case "toDate":
                    return getMessageFormattedDate(dateObject.toDate).toString();
            }
        } else {
            return "";
        }
    };

    const getDateFilters = (type: string) => {
        return (
            <div>
                {filterData.dateItems.map((item, index) => {
                    return item === DateType.Custom ? (
                        <div className="list-item" key={item + index}>
                            <input
                                checked={checkSelectedDateType(type, item)}
                                type="radio"
                                name={type}
                                onChange={handleRadioButtonChange}
                                value={item}
                            ></input>
                            <div className="date-container">
                                <div className="date-item">
                                    <label>From</label>
                                    <CustomDatePicker
                                        maxDate={getCurrentDate()}
                                        value={getDateValue(type, "fromDate", item)}
                                        className={`mb-3 ${
                                            dateValidation[type as keyof IDateValidationObject].fromDate
                                                ? "warning-highlight"
                                                : ""
                                        }`}
                                        disabled={!checkSelectedDateType(type, item)}
                                        onChange={(date: Date | undefined) =>
                                            handleSentDateChange("fromDate", type, date)
                                        }
                                        clearFilter={() => handleClearDateFilter("fromDate", type, undefined)}
                                        resetDateValues={!checkSelectedDateType(type, item)}
                                        clearDatePicker={clearFromDatePicker}
                                        onSubmit={() => {
                                            //
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="date-container">
                                <div className="date-item">
                                    <label>To</label>
                                    <CustomDatePicker
                                        maxDate={getCurrentDate()}
                                        value={getDateValue(type, "toDate", item)}
                                        className={`mb-3 ${
                                            dateValidation[type as keyof IDateValidationObject].toDate
                                                ? "warning-highlight"
                                                : ""
                                        }`}
                                        disabled={!checkSelectedDateType(type, item)}
                                        onChange={(date: Date | undefined) =>
                                            handleSentDateChange("toDate", type, date)
                                        }
                                        clearFilter={() => handleClearDateFilter("toDate", type, undefined)}
                                        resetDateValues={!checkSelectedDateType(type, item)}
                                        clearDatePicker={clearToDatePicker}
                                        onSubmit={() => {
                                            //
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="list-item" key={item + index}>
                            <input
                                checked={checkSelectedDateType(type, item)}
                                data-test-auto={AutomationIdConstants.commonSihRih.FilterRadioBtn}
                                type="radio"
                                name={type}
                                onChange={handleRadioButtonChange}
                                value={item}
                            ></input>
                            <label>{getDateTypeNames(item)}</label>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleSaveFilterTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        if (e.target.value.length <= ReportsFilterConstants.FILTER_NAME_MAX_LENGTH) {
            setFilterTextInput(e.target.value);
            setFilterTextInputValidation(false);
            setShowDuplicateFilterValidation(false);
        }
    };

    const isDRFilterType = () => {
        return filterType === FilterType.DRH;
    };

    const isFilterTextValid = () => {
        // Check if filter name is valid
        if (!checkInputIsBlank(filterTextInput)) {
            setFilterTextInputValidation(true);
            return false;
        }
        // Check for duplicate filter name
        if (filters.data && filters.data.some(item => item.name === filterTextInput)) {
            setShowDuplicateFilterValidation(true);
            return false;
        }
        return true;
    };

    const handleSaveFilterModal = () => {
        if (!isFilterTextValid()) {
            return false;
        }
        handleCreateFilter(filterTextInput.trim());
        hideSaveFilterModal();
        setFilterTextInput("");
    };

    const handleSaveAndApply = () => {
        // Handle save and apply filters
        if (!isFilterTextValid()) {
            return false;
        }
        handleCreateFilter(filterTextInput, true);
        hideSaveFilterModal();
        setFilterTextInput("");
        setEnableClearFilter(false);
        setResetFilterEnable(false);
    };

    const handleDeleteFilter = () => {
        // Delete filter logic
        if (selectedFilter && selectedFilter.name) {
            const deleteFilterId = filters.data.find(filter => filter.name === selectedFilter.name)?.id || 0;
            props.handleDeleteFilter(deleteFilterId);
            logger.trackEvent({
                name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.FilterDeleted}`,
                properties: {
                    page: getPageTitle(),
                    filterId: deleteFilterId,
                },
            });
        }
        setShowDeleteModal(false);
    };

    const handleClearFilter = () => {
        // handle clear filter state
        clearFilterConditions();
        setEnableClearFilter(true);
        setResetFilterEnable(false);
        logger.trackEvent({
            name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.FilterCleared}`,
            properties: {
                page: getPageTitle(),
            },
        });
    };

    const handleHideSaveFilterModal = () => {
        hideSaveFilterModal();
        setFilterTextInput("");
        setFilterTextInputValidation(false);
        setShowDuplicateFilterValidation(false);
    };

    return (
        <LoadingOverlay className="filter-loader">
            <div className="dropdown-container">
                <div className="dropdown" title={filterDropdownValue}>
                    <Dropdown
                        className="secondary-dropdown"
                        onChange={option => {
                            handleFilterDropdownChange(option);
                        }}
                        placeholder={ReportsFilterConstants.FILTER_DROPDOWN_PLACEHOLDER_TEXT}
                        value={filterDropdownValue}
                        options={filterDropdownOptions || []}
                    ></Dropdown>
                    {filterDropdownValue && (
                        <div
                            title="clear"
                            data-test-auto={AutomationIdConstants.commonSihRih.FiltersClearBtn}
                            className="clear-button"
                            onClick={handleClearDropDownFilter}
                        >
                            <CloseIcon width={14} height={14} color={ColorConstants.white} />
                        </div>
                    )}
                </div>
                <div className="header-icons-container">
                    <div className="message-toolbar-action">
                        <span
                            onClick={handleOnClickDelete}
                            data-test-auto={AutomationIdConstants.commonSihRih.FiltersDeleteFilterBtn}
                            title={ReportsFilterConstants.FILTER_DELETE_TITLE}
                            className={!filterDropdownValue ? "toolbar-icon-disabled" : ""}
                        >
                            <DeleteIcon
                                width={17}
                                height={17}
                                disabled={!filterDropdownValue}
                            />
                        </span>
                    </div>

                    <div className="message-toolbar-action">
                        <span
                            onClick={handleClearFilter}
                            title={ReportsFilterConstants.FILTER_RESET_TITLE}
                            className={resetFilterEnable ? "" : "toolbar-icon-disabled"}
                        >
                            <ResetFilterIcon
                                width={16}
                                height={16}
                                disabled={!resetFilterEnable}
                            />
                        </span>
                    </div>
                </div>
            </div>

            <Form>
                
                {showInvalidDateWarning && (
                    <p className="text-danger warning-text">
                        {ReportsFilterConstants.INVALID_DATERANGE_FIELDS_WARNING_MESSAGE}
                    </p>
                )}
                
                {!showInvalidDateWarning && showRequiredFieldWarning && (
                <p className="text-danger warning-text">
                    {ReportsFilterConstants.REQUIRED_FIELDS_WARNING_MESSAGE}
                </p>
                )}
                
                <Accordion flush>
                    {showSenders && (
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="accordion-header-container">
                                    <h4 className="marT0">
                                        {filterType === FilterType.SIH || filterType === FilterType.DRH
                                            ? ReportsFilterConstants.FILTER_MODAL_SENDER_ACCORDION_TITLE
                                            : ReportsFilterConstants.FILTER_MODAL_RECIPIENT_ACCORDION_TITLE}
                                    </h4>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <SearchInput
                                    placeholder={
                                        filterType === FilterType.SIH || filterType === FilterType.DRH
                                            ? ReportsFilterConstants.FILTER_MODAL_SENDER_SEARCH_PLACEHOLDER
                                            : ReportsFilterConstants.FILTER_MODAL_SENDER_RECIPIENT_PLACEHOLDER
                                    }
                                    value={senderSearchValue}
                                    onChange={handleSenderSearchChange}
                                />
                                <div className="checkbox-list-item">
                                    <Checkbox
                                        id={"chkMessage_all"}
                                        text=""
                                        size={CheckBoxSize.sm}
                                        indeterminate={getIndeterminateValue()}
                                        checked={getCheckedValue()}
                                        onChange={e => selectAllSenders(e)}
                                        cancelledCheckBox={AutomationIdConstants.commonSihRih.FiltersCancelledText}

                                    />
                                    <span>
                                        {!senderSearchValue
                                            ? ReportsFilterConstants.FILTER_MODAL_SEARCH_ALL
                                            : ReportsFilterConstants.FILTER_MODAL_SEARCH_ALL_TEXT}
                                    </span>
                                </div>
                                <div className="list-items-wrapper">
                                    {sendersList?.length > 0 ? (
                                        sendersList.sort((a, b) => {
                                            const isASelected = filterCondition.emailAddress?.includes(a.email);
                                            const isBSelected = filterCondition.emailAddress?.includes(b.email);

                                            // Sort selected items to the top
                                            if (isASelected === isBSelected) return 0;
                                            return isASelected ? -1 : 1;
                                        })
                                        .map((item, index) => {
                                            return (
                                                <div className="checkbox-list-item" key={item.email + index}>
                                                    <Checkbox
                                                        id={index.toString()}
                                                        value={item.email}
                                                        size={CheckBoxSize.sm}
                                                        checked={filterCondition.emailAddress?.includes(item.email)}
                                                        onChange={handleSendersSelection}
                                                    />
                                                    <span className="ellipsis pointer" title={item.name}>
                                                        {item.name}
                                                    </span>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p className="search-not-found-text">
                                            {ReportsFilterConstants.FILTER_MODAL_SENDER_SEARCH_NOT_FOUND}
                                        </p>
                                    )}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}

                    {(
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className={sentDateAccordionValidation ? "warning-highlight" : ""}>
                                <div className="accordion-header-container">
                                    <h4 className="marT0">
                                        {filterType === FilterType.SIH || filterType === FilterType.DRH
                                            ? ReportsFilterConstants.FILTER_MODAL_SENT_DATE_ACCORDION_TITLE
                                            : ReportsFilterConstants.FILTER_MODAL_RECEIVED_DATE_ACCORDION_TITLE}
                                    </h4>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="date-accordion">{getDateFilters("sentDate")}</Accordion.Body>
                        </Accordion.Item>
                     )}

                    {isDRFilterType() && (
                     <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <div className="accordion-header-container">
                                <h4 className="marT0">
                                    {ReportsFilterConstants.FILTER_MODAL_REQUEST_STATUS_ACCORDION_TITLE}
                                </h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            {filterData.requestStatusItems?.length > 0 &&
                                filterData.requestStatusItems.map((item: DocumentRequestStatusHistoryReport, index) => {
                                    return (
                                        <div className="checkbox-list-item" key={DocumentRequestStatusHistoryReport[item]}>
                                            <Checkbox
                                                id={DocumentRequestStatusHistoryReport[item] + index}
                                                size={CheckBoxSize.sm}
                                                value={item}
                                                checked={filterCondition?.requestStatus?.includes(item)}
                                                onChange={handleRequestStatusSelection}
                                            ></Checkbox>
                                            <span>{getRequestStatusName(item)}</span>
                                        </div>
                                    );
                                })}
                        </Accordion.Body>
                    </Accordion.Item>
                    )}

                    {!isDRFilterType() && (
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            <div className="accordion-header-container">
                                <h4 className="marT0">
                                    {ReportsFilterConstants.FILTER_MODAL_LINK_STATUS_ACCORDION_TITLE}
                                </h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            {filterData.linkStatusItems?.length > 0 &&
                                filterData.linkStatusItems.map((item: LinkStatus, index) => {
                                    return (
                                        <div className="checkbox-list-item" key={LinkStatus[item]}>
                                            <Checkbox
                                                id={LinkStatus[item] + index}
                                                size={CheckBoxSize.sm}
                                                value={item}
                                                checked={filterCondition.linkStatus.includes(item)}
                                                onChange={handleLinkStatusSelection}
                                            ></Checkbox>
                                            <span>{getLinkStatusName(item)}</span>
                                        </div>
                                    );
                                })}
                        </Accordion.Body>
                    </Accordion.Item>
                    )}

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <div className="accordion-header-container">
                                <h4 className="marT0">
                                    {ReportsFilterConstants.FILTER_MODAL_TRANSFER_MODE_ACCORDION_TITLE}
                                </h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            {filterData.transferModeItems?.length > 0 &&
                                filterData.transferModeItems.map((item: MethodOfTransfer, index) => {
                                    return (
                                        <div className="checkbox-list-item" key={MethodOfTransfer[item]}>
                                            <Checkbox
                                                id={MethodOfTransfer[item] + index}
                                                value={item}
                                                size={CheckBoxSize.sm}
                                                checked={filterCondition.transferMode?.includes(item)}
                                                onChange={handleTransferModeSelection}
                                            ></Checkbox>
                                            <span>{getTransferModeName(item)}</span>
                                        </div>
                                    );
                                })}
                        </Accordion.Body>
                    </Accordion.Item>

                    {(
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className={activityDateAccordionValidation ? "warning-highlight" : ""}>
                            <div className="accordion-header-container">
                                <h4 className="marT0">
                                    {ReportsFilterConstants.FILTER_MODAL_ACTIVITY_LOG_ACCORDION_TITLE}
                                </h4>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="date-accordion">{getDateFilters("activityLog")}</Accordion.Body>
                    </Accordion.Item>
                    )}
                </Accordion>
            </Form>

            <CustomModal
                className="filter-action-modal save-filter"
                data-test-auto={AutomationIdConstants.commonSihRih.FiltersSaveFilter}
                show={showSaveFilterModal}
                title={ReportsFilterConstants.SAVE_FILTER_MODAL_TITLE}
                cancelButtonName={"Cancel"}
                confirmButtonName={"Save"}
                additionalActionButtonName={"Save & Apply"}
                onHide={handleHideSaveFilterModal}
                onSubmit={handleSaveFilterModal}
                onAdditionalButtonClick={handleSaveAndApply}
            >
                <div>
                    <input
                        type="text"
                        placeholder="Filter name"
                        data-test-auto={AutomationIdConstants.commonSihRih.FilterPlaceHolderFilterName}
                        maxLength={ReportsFilterConstants.FILTER_NAME_MAX_LENGTH}
                        value={filterTextInput}
                        onChange={handleSaveFilterTextChange}
                    ></input>
                    <br />
                    {showFilterTextInputValidation && (
                        <p
                            className="text-danger"
                            data-test-auto={AutomationIdConstants.commonSihRih.FiltersTextDanger}
                        >
                            {ValidationContants.EmptyInputFieldWarning}
                        </p>
                    )}
                    {showDuplicateFilterValidation && (
                        <p className="text-danger">{ReportsFilterConstants.DUPLICATE_FILTER_VALIDATION}</p>
                    )}
                </div>
            </CustomModal>

            <CustomModal
                className="filter-action-modal"
                show={showDeleteModal}
                data-test-auto={AutomationIdConstants.commonSihRih.FilterDeleteFilterTxt}
                title={ReportsFilterConstants.DELETE_FILTER_MODAL_TITLE}
                cancelButtonName={"Cancel"}
                confirmButtonName={"Delete"}
                isConfirmModal={true}
                onHide={() => setShowDeleteModal(false)}
                onSubmit={handleDeleteFilter}
                cancelButtonAutomationId={AutomationIdConstants.commonSihRih.CustomModalCancelBtn}
                conformButtonAutomationId={AutomationIdConstants.commonSihRih.CustomModalSaveBtn}
            >
                <div>
                    <span>{ReportsFilterConstants.DELETE_FILTER_MODAL_TEXT}</span>
                </div>
            </CustomModal>
            <Loader loading={filters.isLoading} />
        </LoadingOverlay>
    );
};
