import React, { FC, useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { fetchReceivedMessageHistorySettings } from "../../../actionCreators/adminSettingsActionCreators";
import { getReceivedItemsHistory, getReceivedItemsHistoryDownload, getReceivedItemsHistoryMetrics } from "../../../actionCreators/receivedItemsHistoryActionCreator";
import { getAllFilters, getSendersList } from "../../../actionCreators/reportsActionCreator";
import { FilterType, LinkStatus, MethodOfTransfer } from "../../../core/common/enums";
import { IFilterModel, IItem, initialFilterValues, ISenderList } from "../../../core/viewModels/report/reportViewModel";
import { initialSentItemsRequestObject, IReportItemsHistoryRequestModel } from "../../../core/viewModels/report/SentItemsHistoryModel";
import { ReportConstants } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import ColumnOption from "./parts/ColumnOption";
import { Drawer } from "./parts/Drawer";
import { activityLogFormatter, dataFormatter, fileNameFormatter, linkStatusFormatter, recipientFormatter, transferModeFormatter } from "./parts/Formatter";
import ReportHeader from "./parts/ReportHeader";
import ReportTable from "./parts/ReportTable";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";


const columns = [
    {
        text: "Sender Email",
        dataField: "senderEmail",
        sort: true,
        title: (cell: string) => cell,
        classes: "ellipsis",
        headerStyle: { width: "150px" },
        hidden: false,
    },
    {
        text: "Recipient(s)",
        dataField: "recipients",
        sort: true,
        classes: "ellipsis",
        headerStyle: { width: "150px" },
        formatter: (cell: string) => recipientFormatter(cell),
        hidden: false,
    },
    {
        text: "File(s)",
        dataField: "files",
        sort: true,
        classes: "ellipsis",
        headerStyle: { width: "150px" },
        formatter: (cell: string) => fileNameFormatter(cell),
        hidden: false,
    },
    {
        text: "Received Date",
        dataField: "receivedDate",
        sort: true,
        headerStyle: { width: "200px" },
        formatter: (cell: string) => dataFormatter(cell),
        hidden: false,
    },
    {
        text: "Activity log",
        dataField: "activityLog",
        sort: true,
        classes: "ellipsis",
        headerStyle: { maxWidth: "200px" },
        formatter: (cell: string) => activityLogFormatter(cell),
        hidden: false,
    },
    {
        text: "Link Status",
        dataField: "linkStatus",
        formatter: (cell: LinkStatus) => linkStatusFormatter(cell),
        sort: true,
        hidden: false,
    },
    {
        text: "Transfer Mode",
        dataField: "methodOfTransfer",
        headerStyle: { maxWidth: "150px" },
        classes: "ellipsis",
        formatter: (cell: MethodOfTransfer) => transferModeFormatter(cell),
        sort: true,
        hidden: false,
    },
];

const initialColumnOrder =
    [
        { id: 1, name: "Sender Email", key: "senderEmail" },
        { id: 2, name: "Recipient(s)", key: "recipients", required: true },
        { id: 3, name: "File(s)", key: "files" },
        { id: 4, name: "Received Date", key: "receivedDate" },
        { id: 5, name: "Activity log", key: "activityLog" },
        { id: 6, name: "Link Status", key: "linkStatus" },
        { id: 7, name: "Transfer Mode", key: "methodOfTransfer" },
    ];

const ReceiveItemHistory: FC = () => {
    const [showColumnOptions, setShowColumnOptions] = useState<boolean>(false);
    const [columnList, setColumnList] = useState<ColumnDescription[]>(columns);
    const [columnOrder, setColumnOrder] = useState<IItem[]>(initialColumnOrder);
    const [visibleColumns, setVisibleColumns] = useState<IItem[]>(columnOrder);
    const [defaultColumnOrder, setDefaultColumnOrder] = useState<IItem[]>(initialColumnOrder);
    const [defaulVisibleColumns, setDefaulVisibleColumns] = useState<IItem[]>(defaultColumnOrder);
    const userProfile = useSelector((state: ApplicationState) => state.userProfile);
    const receivedItemsStore = useSelector((state: ApplicationState) => state.receivedItemsReportState);
    const filterStore = useSelector((state: ApplicationState) => state.reportsState.filters);
    const isDownloading = useSelector((state: ApplicationState) => state.receivedItemsReportState.isDownloading);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [notSaved, setNotSaved] = useState<boolean>(false);
    const reportsData = receivedItemsStore.data;
    const [selectedFilter, setSelectedFilter] = useState<IFilterModel>({
        ...initialFilterValues,
        userId: userProfile.userId,
        filterSource: FilterType.RIH,
        filterCondition: {
            ...initialFilterValues.filterCondition,
            transferMode: [MethodOfTransfer.DropOff,MethodOfTransfer.FolderDropOff, ...initialFilterValues.filterCondition.transferMode]
        }
    });
    const recipients: ISenderList[] = [];
    const dispatch = useDispatch();
    filterStore.sendersList.length > 0 && filterStore.sendersList.map((item) => {
        const senderEmails = recipients.map((item: ISenderList) => item.email);
        if(!senderEmails.includes(item.emailAddress)){
            recipients.push({
                email: item.emailAddress,
                name: item.firstName + " " + item.lastName
            });
            return item.emailAddress;
        }
    });
    useEffect(() => {
        dispatch(getReceivedItemsHistory({ ...initialSentItemsRequestObject }));
        dispatch(getAllFilters(FilterType.RIH));
        dispatch(fetchReceivedMessageHistorySettings());
        dispatch(getSendersList(FilterType.RIH));
        dispatch(getReceivedItemsHistoryMetrics());
        // Track Page view event
        logger.trackPageView(appInsightsData.MessageHistory.ReceivedItems.PageTitle);
    }, []);
    const isVisibleColumn = (id: number) => visibleColumns.some(item => item.id === id);

    const handleColumnChange = () => {
        const rearrangedColumns: ColumnDescription[] = [];
        columnOrder.map(item => {
            columnList.map(listItem => {
                if (item.key === listItem.dataField) {
                    if (isVisibleColumn(item.id)) {
                        rearrangedColumns.push({ ...listItem, style: { display: "table-cell" }, headerStyle: { display: "table-cell" } });
                    } else {
                        rearrangedColumns.push({ ...listItem, style: { display: "none" }, headerStyle: { display: "none" } });
                    }
                }
            });
        });
        setColumnList(rearrangedColumns);
        setDefaultColumnOrder(columnOrder);
        setDefaulVisibleColumns(visibleColumns);
        setShowColumnOptions(false);
        setNotSaved(false);
        logger.trackEvent({
            name: `${appInsightsData.MessageHistory.ReceivedItems.PageTitle} - ${appInsightsData.MessageHistory.Operation.ColumnUpdateApplyClicked}`,
            properties: {
                page: appInsightsData.MessageHistory.ReceivedItems.PageTitle
            }
        });
    };

    const handleTableSorting = (sortField: string, sortOrder: string) => {
        const payload = {
            ...initialSentItemsRequestObject,
            sortColumn: sortField,
            sortOrder: sortOrder,
            filterId: selectedFilter.id === 0 ? 0 : -1,
            filterParameters: selectedFilter.id === 0 ? selectedFilter.filterCondition : undefined
        };
        dispatch(getReceivedItemsHistory(payload));
    };

    const fetchReportsData = (payload?: IReportItemsHistoryRequestModel, callback?: () => void) => {
        if(payload){
            dispatch(getReceivedItemsHistory(payload,  () => {
                if(callback){
                    callback();
                }
            }));
        }else{
            dispatch(getReceivedItemsHistory(initialSentItemsRequestObject));
        }
    };
    const handleDownload = () => {
        const count = reportsData[0]?.count;
        if (selectedFilter.id == 0) {
            // download data with custom filter
            dispatch(getReceivedItemsHistoryDownload({
                ...initialSentItemsRequestObject,
                filterId: 0,
                filterParameters: selectedFilter.filterCondition
            },count));
        } else {
            // download data with filter id -1 (Backend will check for selected filter. No need to pass filter params)
            dispatch(getReceivedItemsHistoryDownload(initialSentItemsRequestObject, count));
        }
    };
    const handleCancelColumnOptions = () => {
        if (notSaved) {
            setColumnOrder(defaultColumnOrder);
            setVisibleColumns(defaulVisibleColumns);
        }
        setShowColumnOptions(false);
    };

    return <LoadingOverlay className="reports-container">
        <ReportHeader
            title={ReportConstants.RIH_TITLE}
            filterType={FilterType.RIH}
            sendersList={recipients} 
            selectedFilter={selectedFilter}
            fetchReportsData={fetchReportsData}
            setSelectedFilter={setSelectedFilter}
            handleColumnDrawer={setShowColumnOptions}
            handleDownload={handleDownload}
            isDownloading={!reportsData.length || isDownloading}
            setCurrentPageNumber={setCurrentPageNumber}
            setPrevSelectedFilter = {setSelectedFilter}
            prevSelectedFilter = {selectedFilter}
           />
        <ReportTable
            filterType={FilterType.RIH}
            data={Array.isArray(reportsData) ? reportsData : []}
            columns={columnList}
            isClickable={false} 
            count={reportsData[0]?.count}
            currentPageNumber={currentPageNumber}
            selectedFilter={selectedFilter}
            setCurrentPageNumber={setCurrentPageNumber}
            fetchReportsData={fetchReportsData}
            handleTableSorting={handleTableSorting}
            />
        <Drawer
            show={showColumnOptions}
            onSubmit={() => handleColumnChange()}
            title={"Manage Columns"}
            className="manage-columns-modal-container"
            confirmButtonName={"Apply"}
            cancelButtonName={"Cancel"}
            enableClearFilter={true}
            onCancelButtonClick={handleCancelColumnOptions}
            onHide={handleCancelColumnOptions}
        >
            <ColumnOption
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                setNotSaved={setNotSaved}
                pageTitle={appInsightsData.MessageHistory.ReceivedItems.PageTitle}
            />
        </Drawer>
        <Loader loading={receivedItemsStore.isLoading} />
    </LoadingOverlay>;
};

export default ReceiveItemHistory;