import React from "react";
import { ExchangeIcon } from "../../svg/ProductLogoCollection";
import "./style.scss";
import { convertTextToHtml } from "../../../helper/HelperFunctions";
import { PaperClipIcon } from "../../svg/IconCollection";
import { getDateForDocumentExpiryView, getFormattedDueDate, getFormattedSentDateForDetailedView } from "../../../helper/DateHelperFunctions";
import { IDocumentRequestFile, IDocumentRequestModel } from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { DetailedStatus } from "../../../core/common/enums";

interface IDocumentPrintProps {
  document: IDocumentRequestModel;
  titleText?: string;
  uploadedFilesCount: number;
}

/* eslint-disable react/display-name */
export const DocumentRequestPrint = React.forwardRef((props: IDocumentPrintProps, ref: any) => {
  const { document, uploadedFilesCount } = props;

  return (
    <div className="print-dr-container" ref={ref}>
      <header>
        <ExchangeIcon width={110} height={25} />
      </header>
      <hr />
      <div className="print-message-info">
        <p>{props?.titleText ?? "REQUEST DOCUMENTS MESSAGE"}</p>

        <h4>{document?.subject ? document?.subject : "(no subject)"}</h4>
        <h5>{document?.recipient?.email?.trim()}</h5>

        <p>{getFormattedSentDateForDetailedView(document?.sentDate)}</p>
        {(document.status === DetailedStatus.OPEN || document.status === DetailedStatus.REOPEN) &&
          document.dueDate && (
            <p>Due On: {getFormattedDueDate(document.dueDate)}</p>
          )}
        {document.status === DetailedStatus.COMPLETED && (
          <p>Expires On: {getDateForDocumentExpiryView(document.updatedDate, document.expiryDays)} </p>
        )}


      </div>
      {document?.requestedFiles && document?.requestedFiles?.length > 0 ? <div className="attachment-list-container">
        <span className="attachment-list-title">
          <PaperClipIcon />
          <span style={{ marginLeft: "7px" }}> {uploadedFilesCount ?? 0} attachments</span>

        </span>
        <div>
          <ul>
            {
              document?.requestedFiles && document?.requestedFiles.map((attachment: IDocumentRequestFile, index: number) => {
                return (
                  <li key={index} className="attachment">
                    <span>{attachment.documentCategoryName} {attachment.documentCategoryName ? "-" : ""}  </span>
                    <span>{attachment.name} : </span>
                    {attachment.uploadedFiles && attachment.uploadedFiles.length > 0 && attachment.uploadedFiles.map((file, index) => {
                      return (
                        <span key={index + file.fileName}>
                          {file.fileName}
                          {index < attachment.uploadedFiles.length - 1 ? ", " : ""}
                        </span>
                      );
                    })}
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div> : null}

      {document?.message?.length > 0 ? <div className="message-container">
        <div
          dangerouslySetInnerHTML={{
            __html: convertTextToHtml(document?.message ?? ""),
          }}
        ></div>
      </div> : null}

    </div>
  );
});
