import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ApplicationState } from "./store";
import { signinRedirect, signinRedirectWithCallbackArgs } from "./oidcClient/userService";
import FeatureDisabled from "./components/common/FeatureDisabled";
import { PageDisabledTypes } from "./core/viewModels/user/UserViewModel";
import { actionTypes } from "./types/postAuthTypes";
import { getProductSubscribed } from "./actionCreators/postAuthActionCreators";
import LoadingScreen from "./components/LoadingScreen";
import LayoutContainer from "./components/navigation/Layout/LayoutContainer";
import { getCookie, isExchangeUser } from "./helper/HelperFunctions";
import { useLocation } from "react-router";
import { requestUserProfile } from "./actions/userProfileActions";
import { resetUserCache } from "./actionCreators/layoutActionCreators";
import { SideMenuConstants } from "./helper/Constants";
import { ISideMenuItem } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";

function ProtectedRoute({ component: Component, ...rest }: any) {
    const userState = useSelector((state: ApplicationState) => state.userAuth);
    const postAuthLoading = useSelector((state: ApplicationState) => state.postAuthState.postAuthLoading);
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const [productPermission, setProductPermission] = useState<boolean>(false);

    const { exchangeEnabled, productEnabled, productSubscribed,
        isPageAccessDenied, exchangeEnabledLoading, isPageAccessDeniedLoading,
        isProductSubscribedLoading } = useSelector((state: ApplicationState) => state.postAuthState);
    const sideMenuData = useSelector((state: ApplicationState) => state.layoutState.sideMenuData);
    const location = useLocation();
    const messageHistorySettings = useSelector(
        (state: ApplicationState) => state.adminSettingsState.messageHistorySettings
    );
    const requestHistorySettings = useSelector((state: ApplicationState) => state.adminSettingsState.requestHistorySettings);

    useEffect(() => {
        (function login() {
            if (!userState.isLoadingUser && !userState.user) {
                const path = location.pathname;
                if (path === "/") {
                    signinRedirect();
                } else {
                    signinRedirectWithCallbackArgs(location.pathname);
                }
            }
        })();
        dispatch(requestUserProfile());
    }, []);

    useEffect(() => {
        if (userState.user && getCookie("userAutoLogout") !== "1") {
            resetcache();
        } 
    }, [userState.user]);


    const resetcache = () => {
        dispatch(
            resetUserCache(() => {
                dispatch(getProductSubscribed());
            })
        );
    };
    useEffect(() => {
        const hasPermission = isExchangeUser(userState.user);
        setProductPermission(hasPermission);
    }, [user.userId]);

    useEffect(() => {
        const exchangeEnabled = productSubscribed && productEnabled && productPermission;
        dispatch({ type: actionTypes.SET_EXCHANGE_ENABLE_STATUS, payload: exchangeEnabled });
    }, [productEnabled, productSubscribed, productPermission]);

    useEffect(() => {
        // Map over sidemenu to check whether the location pathname is allowed menu
        if (sideMenuData && sideMenuData.length > 0) {
            let isAccessAllowed = true;
            sideMenuData && sideMenuData.forEach((section) => {
                section.items.forEach((menu) => {
                    if (((location.pathname.toLocaleLowerCase() === menu.route.toLocaleLowerCase()
                        || isAdminSettingsRoute(location.pathname.toLocaleLowerCase(), menu)) && menu.disabled)
                        || isSIHRoute(location.pathname.toLocaleLowerCase())
                        || isRIHRoute(location.pathname.toLocaleLowerCase())
                        || isRequestReportingRoute(location.pathname.toLocaleLowerCase())) {
                        isAccessAllowed = false;
                        return;
                    }
                    menu.items &&
                        menu.items?.length > 0 &&
                        menu.items.map((subMenu) => {
                            if (location.pathname.toLocaleLowerCase() === subMenu.route.toLocaleLowerCase() && subMenu.disabled) {
                                isAccessAllowed = false;
                                return;
                            }
                        });
                });
            });
            dispatch({
                type: actionTypes.SET_PAGE_LEVEL_ACCESS,
                payload: !isAccessAllowed
            });
        }
    }, [sideMenuData, messageHistorySettings, requestHistorySettings, location.pathname]);

    const getPageDisableType = () => {
        if (!productSubscribed) {
            return PageDisabledTypes.SubscriptionTier;
        } else if (!productEnabled) {
            return PageDisabledTypes.ProductDisabled;
        } else if (!productPermission) {
            return PageDisabledTypes.NoPermission;
        } else if (isPageAccessDenied) {
            return PageDisabledTypes.PageAccessDenied;
        }
        else {
            return 0;
        }
    };

    //checking if the admin-settings menu is enabled by checking if the default route "/admin-settings/authentication-questions" is enabled
    const isAdminSettingsRoute = (route: string, menu: ISideMenuItem) => {
        if (route.includes(SideMenuConstants.ADMIN_SETTINGS_ROUTE) && menu.route.toLowerCase() == SideMenuConstants.ADMIN_SETTINGS_AUTHENTICATION_QUESTIONS_ROUTE) {
            return true;
        }
    };

    const isSIHRoute = (route: string) => {
        if (messageHistorySettings && route.includes(SideMenuConstants.SENT_ITEMS_HISTORY_ROUTE)) {
            return !messageHistorySettings.sentItemsSettings?.isUserAllowedToViewReports;
        }
    };

    const isRIHRoute = (route: string) => {
        if (messageHistorySettings && route.includes(SideMenuConstants.RECEIVED_ITEMS_HISTORY_ROUTE)) {
            return !messageHistorySettings.receivedItemsSettings?.isUserAllowedToViewReceivedMessageHistoricalReport;

        }
    };

    const isRequestReportingRoute = (route: string) => {
        if (requestHistorySettings && route.includes(SideMenuConstants.DOCUMENT_REQUESTS_HISTORY_ROUTE)) {
            return !requestHistorySettings?.isUserAllowedToViewDocumentRequestHistoricalReport;
        }
    };

    return ((!userState.isLoadingUser && userState.user && !postAuthLoading && !!user.userId && !exchangeEnabledLoading
        && !isProductSubscribedLoading) ?
        <LayoutContainer>
            {isPageAccessDeniedLoading ? <LoadingScreen /> : !exchangeEnabled || isPageAccessDenied ?
                <FeatureDisabled type={getPageDisableType()} /> :
                <Component {...rest} />}
        </LayoutContainer> : <LoadingScreen />
    );
}

export default ProtectedRoute;
