import moment from "moment";
import { DateFormatConstants } from "./Constants";

export function getMessageFormattedDate(date: Date) {
    return moment.utc(date).add(-new Date().getTimezoneOffset(), "minutes").local().format("MM/DD/YYYY");
}

export function getFormattedSentDate(date: Date): any {
    if (!date) {
        return "NA";
    } 
    const localDate = moment.utc(date).local();
    const currentDate = new Date();
    const messageWeek = localDate.isoWeek();
    const messageYear = localDate.year();
    
    const currentWeek = moment(currentDate).isoWeek();
    const currentYear = currentDate.getFullYear();

    if (messageWeek === currentWeek && messageYear===currentYear) {
        if (localDate.isSame(currentDate, "day")) {
            return localDate.format(DateFormatConstants.SAME_DAY_TIME_FORMAT); // on the same day - <hr:min> 24 hr format
        }
        return `${localDate.format(DateFormatConstants.CURRENT_WEEK_DATE_FORMAT)}`; // during current week - <day mm-dd>
    }
    return localDate.format(DateFormatConstants.DEFAULT_DATE_FORMAT); // before current week - <mm/dd/yyyy>
}

export function getFormattedSentDateForDetailedView(date: Date): any {
    const localDate = moment.utc(date).local();
    const currentDate = new Date();
    const seventhDate = new Date();
    seventhDate.setDate(seventhDate.getDate() + 7);
    if (date > currentDate) {
        return localDate.format(DateFormatConstants.DEFAULT_TIME_FORMAT);
    } else if (currentDate < seventhDate) {
        return localDate.format(DateFormatConstants.MESSAGE_DETAIL_DATE_FORMAT);
    } else {
        return localDate.format(DateFormatConstants.DEFAULT_DATE_FORMAT);
    }
}
export const getDateForDocumentExpiryView = (date: Date, days: number): string => {
    return moment.utc(date).local().add(days, "days").format(DateFormatConstants.DOCUMENT_EXPIRY_FORMAT);
};
export const getFormattedDueDate = (date: Date): string => {
    return moment(date.toString()).format(DateFormatConstants.DOCUMENT_EXPIRY_FORMAT);
};

export function getExpiryDateFromDays(date: Date, days: number, format?:string) {
    return moment.utc(date).local().add(days, "days").format(format ?? DateFormatConstants.DEFAULT_DATE_FORMAT);
}

export function getCurrentDate() {
    return new Date(new Date().setHours(0, 0, 0, 0));
}

export function getDateAfterYears(date: Date, years: number) {
    date = new Date(date);
    return new Date(date.setFullYear(date.getFullYear() + years));
}

export function getFormattedLocalDateTime(utcDate: Date | string, format: string) {
    return moment
        .utc(utcDate)
        .local()
        .format(format ?? DateFormatConstants.DEFAULT_DATE_FORMAT);
}
const LAST_TAX_YEAR = 2014;
export const getTaxYears = () => {
    const taxYears = [];
    let year = new Date().getFullYear()+3;
    while (year >= LAST_TAX_YEAR) {
        taxYears.push(year);
        year--;
    }
    return taxYears;
};

export const getYearFromDate = (date: Date): number => {
    return new Date(date).getFullYear();
};

export const isDateEqual = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};


// Function to check whether the date (format) is valid or not 
export const isDateValid = (date: Date): boolean => date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() !== 0;