import React, { FC } from "react";
import { ExchangeIcon } from "./svg/ProductLogoCollection";

const LoadingScreen: FC = () => {
    return (
        <div className="loading-screen-container">
            <ExchangeIcon />
            <div className="text-wrapper">
                <div className="loading-text">
                    <div className="dot-spin-loader"></div></div>
            </div>

        </div>
    );
};

export default LoadingScreen;
