import React, { useEffect, useState } from "react";
import { DocumentRequestStatusHistoryReport, FilterType, MethodOfTransfer } from "../../../core/common/enums";
import { ReportConstants } from "../../../helper/Constants";
import ReportHeader from "./parts/ReportHeader";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
    recipientFormatter,
    dataFormatter,
    activityLogFormatter,
    transferModeFormatter,
    requestStatusFormatter,
    uploadedFilesFormatter,
    documentNameFormatter,
} from "./parts/Formatter";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { IFilterModel, IItem, initialFilterValues, ISenderList } from "../../../core/viewModels/report/reportViewModel";
import {
    IReportItemsHistoryRequestModel,
    initialSentItemsRequestObject,
} from "../../../core/viewModels/report/SentItemsHistoryModel";
import ReportTable from "./parts/ReportTable";
import { fetchRequestHistorySettings } from "../../../actionCreators/adminSettingsActionCreators";
import {
    getDocumentHistoryMetrics,
    getDocumentReportHistory,
} from "../../../actionCreators/documentRequestHistoryActionCreator";
import ColumnOption from "./parts/ColumnOption";
import { Drawer } from "./parts/Drawer";
import { ColumnDescription } from "react-bootstrap-table-next";
import { logger } from "../../../oidcClient/authProvider";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { getAllFilters, getSendersList } from "../../../actionCreators/reportsActionCreator";

const columns = [
    {
        text: "Sender",
        dataField: "senderName",
        sort: true,
        title: (cell: string) => cell,
        classes: "ellipsis",
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Recipient(s)",
        dataField: "recipients",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => recipientFormatter(cell),
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Documents Requested",
        dataField: "documentNames",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => documentNameFormatter(cell),
        hidden: false,
        headerStyle: { width: "140px" },
    },
    {
        text: "Uploaded File(s)",
        dataField: "requestedDocuments",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => uploadedFilesFormatter(cell),
        hidden: false,
        headerStyle: { width: "115px" },
    },
    {
        text: "Sent Date",
        dataField: "sentDate",
        sort: true,
        formatter: (cell: string) => dataFormatter(cell),
        hidden: false,
        headerStyle: { width: "130px" },
    },
    {
        text: "Activity log",
        dataField: "activityLog",
        sort: true,
        classes: "ellipsis",
        formatter: (cell: string) => activityLogFormatter(cell, true),
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Request Status",
        dataField: "requestStatus",
        formatter: (cell: DocumentRequestStatusHistoryReport) => requestStatusFormatter(cell), //TODO: Add request status formatter
        sort: true,
        hidden: false,
        headerStyle: { width: "100px" },
    },
    {
        text: "Transfer Mode",
        dataField: "methodOfTransfer",
        classes: "ellipsis",
        formatter: (cell: MethodOfTransfer) => transferModeFormatter(cell),
        sort: true,
        hidden: false,
        headerStyle: { width: "100px" },
    },
];

const initialColumnOrder = [
    { id: 1, name: "Sender", key: "senderName", required: true },
    { id: 2, name: "Recipient(s)", key: "recipients" },
    { id: 3, name: "Documents Requested", key: "documentNames" },
    { id: 4, name: "Uploaded File(s)", key: "requestedDocuments" },
    { id: 5, name: "Sent Date", key: "sentDate" },
    { id: 6, name: "Activity log", key: "activityLog" },
    { id: 7, name: "Request Status", key: "requestStatus" },
    { id: 8, name: "Transfer Mode", key: "methodOfTransfer" },
];

const DocumentRequestHistory = () => {
    const { data, isLoading } = useSelector((state: ApplicationState) => state.documentRequestReportState);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const [showColumnOptions, setShowColumnOptions] = useState<boolean>(false);
    const [columnList, setColumnList] = useState<ColumnDescription[]>(columns);
    const [columnOrder, setColumnOrder] = useState<IItem[]>(initialColumnOrder);
    const [visibleColumns, setVisibleColumns] = useState<IItem[]>(columnOrder);
    const [defaultColumnOrder, setDefaultColumnOrder] = useState<IItem[]>(initialColumnOrder);
    const [defaulVisibleColumns, setDefaulVisibleColumns] = useState<IItem[]>(defaultColumnOrder);
    const [notSaved, setNotSaved] = useState<boolean>(false);

    const userProfile = useSelector((state: ApplicationState) => state.userProfile);
    const [selectedFilter, setSelectedFilter] = useState<IFilterModel>({
        ...initialFilterValues,
        userId: userProfile.userId,
        filterSource: FilterType.DRH,
        filterCondition: {
            ...initialFilterValues.filterCondition,
            transferMode: [MethodOfTransfer.DropOff, ...initialFilterValues.filterCondition.transferMode],
        },
    });

    const [prevSelectedFilter, setPrevSelectedFilter] = useState<IFilterModel>({
        ...initialFilterValues,
        userId: userProfile.userId,
        filterSource: FilterType.DRH,
        filterCondition: {
            ...initialFilterValues.filterCondition,
            transferMode: [MethodOfTransfer.DropOff, ...initialFilterValues.filterCondition.transferMode],
        },
    });
    //const recipients: ISenderList[] = [];
    const sendersList: ISenderList[] = [];
    const filterStore = useSelector((state: ApplicationState) => state.reportsState.filters);
    filterStore.sendersList.length > 0 && filterStore.sendersList.map((item) => {
        const senderEmails = sendersList.map((item: ISenderList) => item.email);
        if(!senderEmails.includes(item.emailAddress)){
            sendersList.push({
                email: item.emailAddress,
                name: item.firstName + " " + item.lastName
            });
            return item.emailAddress;
        }});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRequestHistorySettings());
        dispatch(getDocumentReportHistory({ ...initialSentItemsRequestObject }));
        dispatch(getDocumentHistoryMetrics());
        dispatch(getAllFilters(FilterType.DRH));
        dispatch(getSendersList(FilterType.DRH));
    }, []);

    const fetchReportsData = (payload?: IReportItemsHistoryRequestModel, callback?: () => void) => {
        if (payload) {
            dispatch(
                getDocumentReportHistory(payload, () => {
                    if (callback) {
                        callback();
                    }
                })
            );
        } else {
            dispatch(getDocumentReportHistory(initialSentItemsRequestObject));
        }
    };
    

    const handleTableSorting = (sortField: string, sortOrder: string) => {
        const payload = {
            ...initialSentItemsRequestObject,
            sortColumn: sortField,
            sortOrder: sortOrder,
            filterId: selectedFilter.id === 0 ? 0 : -1,
            filterParameters: selectedFilter.id === 0 ? selectedFilter.filterCondition : undefined,
        };
        dispatch(getDocumentReportHistory(payload));
    };

    const handleDownload = () => {
        console.log("Download");
    };
    const handleDraw = () => {
        setShowColumnOptions(!showColumnOptions);
    };

    const isVisibleColumn = (id: number) => visibleColumns.some(item => item.id === id);

    const handleColumnChange = () => {
       const rearrangedColumns: ColumnDescription[] = [];
        columnOrder?.map(item => {
            columnList?.map(listItem => {
                if (item.key === listItem.dataField) {
                    if (isVisibleColumn(item.id)) {
                        rearrangedColumns.push({ ...listItem, style: { display: "table-cell" }, headerStyle: { display: "table-cell" } });
                    } else {
                        rearrangedColumns.push({ ...listItem, style: { display: "none" }, headerStyle: { display: "none" } });
                    }
                }
            });
        });
        setColumnList(rearrangedColumns);
        setShowColumnOptions(false);
        setNotSaved(false);
        setDefaultColumnOrder(columnOrder);
        setDefaulVisibleColumns(visibleColumns);

        logger.trackEvent({
            name: `${appInsightsData.MessageHistory.DocumentRequest.PageTitle} - ${appInsightsData.MessageHistory.Operation.ColumnUpdateApplyClicked}`,
            properties: {
                page: appInsightsData.MessageHistory.DocumentRequest.PageTitle
            }
        });
    };

    const handleCancelColumnOptions = () => {
        if (notSaved) {
            setColumnOrder(defaultColumnOrder);
            setVisibleColumns(defaulVisibleColumns);

        }
        setShowColumnOptions(false);
    };

    return (
        <LoadingOverlay className="reports-container">
            <ReportHeader
                title={ReportConstants.DR_TITLE}
                filterType={FilterType.DRH}
                sendersList={sendersList}
                selectedFilter={selectedFilter}
                fetchReportsData={fetchReportsData}
                setSelectedFilter={setSelectedFilter}
                handleColumnDrawer={handleDraw}
                handleDownload={handleDownload}
                isDownloading={false}
                setCurrentPageNumber={setCurrentPageNumber}
                setPrevSelectedFilter = {setPrevSelectedFilter}
                prevSelectedFilter = {prevSelectedFilter}
            />
            <ReportTable
                filterType={FilterType.DRH}
                data={Array.isArray(data) ? data : []}
                columns={columnList}
                count={data[0]?.Count || 0}
                isClickable={false}
                currentPageNumber={currentPageNumber}
                selectedFilter={selectedFilter}
                setCurrentPageNumber={setCurrentPageNumber}
                fetchReportsData={fetchReportsData}
                handleTableSorting={handleTableSorting}
            />
            <Loader loading={isLoading} />

            <Drawer
                show={showColumnOptions}
                onSubmit={handleColumnChange}
                title={"Manage Columns"}
                confirmButtonName={"Apply"}
                cancelButtonName={"Cancel"}
                enableClearFilter={true}
                className="manage-columns-modal-container"
                onCancelButtonClick={handleCancelColumnOptions}
                onHide={handleCancelColumnOptions}
            >
                <ColumnOption
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    visibleColumns={visibleColumns}
                    setVisibleColumns={setVisibleColumns}
                    setNotSaved={setNotSaved}
                    pageTitle={appInsightsData.MessageHistory.DocumentRequest.PageTitle}
                />
            </Drawer>
        </LoadingOverlay>
    );
};

export default DocumentRequestHistory;
