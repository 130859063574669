import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { AutomationIdConstants } from "../../../../helper/AutomationConstants";

export interface IFilterModalProps {
  children: ReactNode;
  show: boolean,
  enableClearFilter?: boolean,
  className?: string,
  cancelButtonName?: string;
  confirmButtonName?: string;
  additionalActionButtonName?: string;
  title?: string;
  onSubmit: () => void;
  onAdditionalButtonClick?: () => void;
  onHide: () => void;
  onCancelButtonClick?: () => void;

}

export const Drawer: React.FC<IFilterModalProps> = (props) => {
  return (
    <Modal
      className={`filter-modal-container ${props.className ?? ""}`}
      show={props.show}
      onHide={props.onHide}
      backdrop={"static"}
      keyboard={false}
      onClick={(e: React.SyntheticEvent) => { e.stopPropagation(); }}
    >
      <ModalHeader closeButton data-test-auto={AutomationIdConstants.commonSihRih.DrawerCloseBtn}>
        <Modal.Title data-auto-test={AutomationIdConstants.commonSihRih.DrawerTitle}>
          {props.title}
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="button-grey" data-test-auto={AutomationIdConstants.commonSihRih.DrawerCancelBtn}  onClick={props.onHide}>{props.cancelButtonName}</button>
        <button type="button" 
        className={!props.enableClearFilter ? "toolbar-icon-disabled button-primary-blue-2" : "button-primary-blue-2"} 
        disabled={!props.enableClearFilter}
        data-test-auto={AutomationIdConstants.commonSihRih.DrawerSaveBtn}  onClick={props.onSubmit}>{props.confirmButtonName}</button>
        
        {props.additionalActionButtonName && <button type="button" 
          onClick={props.onAdditionalButtonClick} 
          className={!props.enableClearFilter ? "toolbar-icon-disabled button-primary-blue-2" : "button-primary-blue-2"}  
          disabled={!props.enableClearFilter}        
          data-test-auto={AutomationIdConstants.commonSihRih.DrawerSaveAndApplyBtn}>{props.additionalActionButtonName}</button>}
      </Modal.Footer>
    </Modal>
  );
};
