import { LoadingOverlay } from "react-overlay-loader";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { ITabModel } from "../../../core/viewModels/settings/Settings";
import { TabType} from "../../../core/common/enums";
import Tab from "../adminSettings/parts/Tab";
import { TabConstants, UnlockLinkConstants, viewingOthersInboxLabel } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";

export interface IUnlockInfo {
    title: string;
    items: ITabModel[];
} 
const unlockTabList: IUnlockInfo[] = [
    {
        title: UnlockLinkConstants.UNLOCK_HEADER_TITLE_TEXT,
        items: [
            {
                title: TabConstants.UNLOCK_MESSAGE_TAB,
                type: TabType.Messages,
                isActive: true,
            },
            {
                title: TabConstants.UNLOCK_REQUEST_TAB,
                type: TabType.Requests,
                isActive: false,
            },
            {
                title: TabConstants.UNLOCK_ONEHUB_TAB,
                type: TabType.Onehub,
                isActive: false,
            },
        ],
    },
];

const Unlock = () => {
    const [title, setTitle] = useState<string>("");
    const [tabList, setTabList] = useState<ITabModel[]>([]);
    const [activeTab, setActiveTab] = useState<TabType>();
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    useEffect(() => {
        setTabList(unlockTabList[0].items);
        setTitle(unlockTabList[0].title);
        setActiveTab(unlockTabList[0].items.filter(tab => tab.isActive)[0].type);
    }, []);
    
    const handleTabClick = (tabType: TabType) => {
        setTabList(
           tabList.map(tab => (tab.type === tabType ? { ...tab, isActive: true } : { ...tab, isActive: false }))
        );
        setActiveTab(tabType);
    };

    return (
        <LoadingOverlay>
            {mailboxUserData && (
                <p className="others-inbox-info-label">
                    {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                </p>
            )}
            <div className="unlock-loader">
                <div className="unlock-parent-container">
                    <header>
                        <h3>{title}</h3>
                    </header>
                </div>
                <Tab tabs={tabList} handleTabClick={handleTabClick} activeTab={activeTab} />
            </div>
        </LoadingOverlay>
    );
};

export default Unlock;
