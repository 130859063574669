import { Col, FormControl, Row } from "react-bootstrap";
import { ArchiveConstants, SortAndFilterConstants, SortAndFilterIconType } from "../../../helper/Constants";
import { DownloadIcon, RefreshIcon, SearchIcon } from "../../svg/IconCollection";
import { MessageSource, MessageViewType } from "../../../core/common/enums";
import { FiltersCustomComponent } from "../../common/ui/Filters/FiltersCustomComponent";
import SwitchButtonGroup from "../../common/ui/switch/SwitchButtonGroup";
import MessageFileView from "./MessageFileView";
import ArchiveMessage from "./ArchiveMessage";
import { Dispatch, SetStateAction } from "react";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { IPagination } from "../../../core/viewModels/message/SentMessageModel";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { IMessageFileViewModel } from "../../../core/viewModels/message/MessageFileViewModel";
import React from "react";

export interface IArchiveMessageTabProps {
    currentFolder: IFolderModel;
    searchText?: string;
    selectedFolderId: number;
    pagination: IPagination;
    selectedSortOption?: string;
    reload?: boolean;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    handleDownloadSingleAttachment?: (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => void;
    handleResetSearchAndSort: () => void;
    showDownloadModal: boolean;
    setShowDownloadModal: Dispatch<React.SetStateAction<boolean>>;
    selectedFiles: IMessageFileViewModel[];
    setSelectedFiles: Dispatch<React.SetStateAction<IMessageFileViewModel[]>>;
    fileViewSort?: any;
    searchTextBox: string;
    onFilter: React.ChangeEventHandler<HTMLInputElement>;
    setSelectedSortOption:  Dispatch<React.SetStateAction<string>>;
    handleRefreshButtonClick:() => void;
    handleDownloadButtonClick: React.MouseEventHandler<HTMLButtonElement>;
    handleMessageViewToogle?:() => void;
    isFileDownloading: boolean;
    messageViewType: string;
    goToParentFolder: () => void;
}

const ArchiveMessageTab: React.FC<IArchiveMessageTabProps> = props => {
    const {
        currentFolder,
        searchText,
        selectedFolderId,
        pagination,
        selectedSortOption,
        reload,
        setPagination,
        handleDownloadSingleAttachment,
        handleResetSearchAndSort,
        showDownloadModal,
        setShowDownloadModal,
        selectedFiles,
        setSelectedFiles,
        fileViewSort,
        searchTextBox,
        onFilter,
        setSelectedSortOption,
        handleRefreshButtonClick,
        handleDownloadButtonClick,
        handleMessageViewToogle,
        isFileDownloading,
        messageViewType,
    } = props;

    const messageView = ["Mail View", "File View"];

    const sortingData = [
        {
            title: SortAndFilterConstants.SORT_BY_TEXT,
            menus: [
                SortAndFilterConstants.ALPHABETICAL_ORDER_ASC,
                SortAndFilterConstants.ALPHABETICAL_ORDER_DESC,
                SortAndFilterConstants.NEWEST_FIRST_ORDER,
                SortAndFilterConstants.OLDEST_FIRST_ORDER,
            ],
        },
    ];
    
    const getMessageView = () => {
        switch (messageViewType) {
            case messageView[MessageViewType.Mail]:
                return (
                    <ArchiveMessage
                        downloadSingleAttachment={handleDownloadSingleAttachment}
                        pagination={pagination}
                        setPagination={setPagination}
                        folderId={selectedFolderId}
                        currentfolder={currentFolder}
                        searchText={searchText}
                        selectedSortOption={selectedSortOption}
                        resetSearchAndSort={handleResetSearchAndSort}
                        reload={reload}
                    />
                );
            case messageView[MessageViewType.File]:
                return (
                    <MessageFileView
                        downloadSingleAttachment={handleDownloadSingleAttachment}
                        showDownloadModal={showDownloadModal}
                        setShowDownloadModal={setShowDownloadModal}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        currentFolder={currentFolder}
                        sortBy={fileViewSort}
                        handleResetSearchAndSort={handleResetSearchAndSort}
                    />
                );
            default:
                return;
        }
    };


    return(
    <div>
        <header>
            <div className="file-search-wrapper">
                <Row className="search-header-wrapper col-sm-4">
                    <Col sm={10} md={8} className="search-input-wrapper-archive padding-left width-27p">
                        <div className="search-input-wrapper">
                            <FormControl
                                placeholder={ArchiveConstants.ShareFolder.PLACEHOLDER_SEARCH_TEXT}
                                value={searchTextBox}
                                onChange={onFilter}
                                className="search-input search-textbox" />
                            <div className="search-icon">
                                <SearchIcon/>
                            </div>
                        </div>
                    </Col>
                    {messageViewType === messageView[MessageViewType.Mail] && (
                        <Col sm={2} md={2} className="filter-icon-container">
                            <div>
                                <FiltersCustomComponent
                                    groups={sortingData}
                                    selectedSortOption={selectedSortOption}
                                    iconType={SortAndFilterIconType.Sort}
                                    setSelectedSortOption={setSelectedSortOption} />
                            </div>
                        </Col>
                    )}
                    <Col sm={2} md={2} className="refresh-button-wrapper-col">
                        <div
                            className="refresh-button-wrapper"
                            onClick={handleRefreshButtonClick}
                            title={ArchiveConstants.REFRESH_TITLE}
                        >
                            <RefreshIcon/>
                        </div>
                    </Col>
                </Row>
                <div className="file-view-toggle-container col-sm-8">
                    {messageViewType === messageView[MessageViewType.File] && (
                        <button
                            className="button-secondary-blue-default"
                            data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnDownloadFiles}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                !isFileDownloading && handleDownloadButtonClick(event);
                            } }
                            disabled={!selectedFiles.length || isFileDownloading}
                        >
                            <DownloadIcon width={18} height={18} color="#fff" /> Download
                        </button>
                    )}
                    <SwitchButtonGroup
                        selected={messageViewType}
                        onSwitchChange={handleMessageViewToogle}
                        option2ResourceDataId={ResourceIdLocators.MyFiles.MyFilesBtnFileView}
                        option1Text={messageView[MessageViewType.Mail]}
                        option2Text={messageView[MessageViewType.File]} />
                </div>
            </div>
        </header>
        <main>
                {getMessageView()}
        </main>
    </div>
    );
};
export default ArchiveMessageTab;
