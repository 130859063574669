import { Action, Reducer } from "redux";
import produce from "immer";

import * as AdminSettingsActions from "../actionCreators/adminSettingsActionCreators";
import { actionTypes } from "../types/adminSettingsTypes";
import { ICompanyRetentionSettings, IRetentionPeriod } from "../core/viewModels/company/settings/RetentionSettings";
import {
    IAuthenticationMethodSettings,
    initialCompanyAuthenticationSettings,
} from "../core/viewModels/company/settings/AuthenticationSettings";
import {
    IMessageSecuritySettings,
    initialMessageSecuritySettings,
} from "../core/viewModels/message/MessageSecurityQuestionModel";
import { IDropOffSettings, initialDropOffSettings } from "../core/viewModels/company/settings/DropOffSettings";
import { initialCompanyNotificationSettings, intialCompanyRequestNotificationSettings } from "../core/viewModels/company/settings/NotificationsSettings";
import { initialSavedMessageDRSettings, initialSavedMessageSettings, ISavedMessageDRTemplateSettings, ISavedMessageTemplateSettings } from "../core/viewModels/settings/SavedMessageTemplate";

export interface ISupportContact {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export interface ISupportContactSettingsValues {
    supportContactId: number;
    isDisplayPhoneEnabled: boolean;
}

export interface ISentItemsHistorySettingsValues {
    isUserAllowedToViewReports: boolean;
    isUserAllowedToViewTransferOfAllUsers: boolean;
    isUserAllowedToResendDocumentAccessLinks: boolean;
    isUserAllowedToViewAuthenticationQnA: boolean;
}

export interface IReceivedItemsHistorySettingsValues {
    isUserAllowedToViewReceivedMessageHistoricalReport: boolean;
    isUserAllowedToViewTransferOfAllUsers: boolean;
}

export interface IRequestHistorySettingsValues {
    isUserAllowedToViewDocumentRequestHistoricalReport: boolean;
    isUserAllowedToViewDocumentRequestSentByAllUsers: boolean;
}

export interface INotificationsSettings {
    isExpiryNotificationEnable: boolean;
    isUserAllowedToChangeExpiryNotification: boolean;
    isDownloadNotificationEnable: boolean;
    isUserAllowedToChangeDownloadNotification: boolean;
}

export interface IRequestNotificationsSettings {
    isRequestCompletionNotificationEnabled: boolean;
    isUserAllowedToChangeRequestCompletionNotification: boolean;
    isRequestReopenNotificationEnabled: boolean;
    isUserAllowedToChangeRequestReopenNotification: boolean;
}
export interface IAdminSettingsStore {
    isLoading: boolean;
    supportContactList: ISupportContact[];
    supportContactSettings: ISupportContactSettingsValues | undefined;
    messageOptions: {
        retentionPeriodList: IRetentionPeriod[];
        retentionSettings?: ICompanyRetentionSettings;
        authenticationSettings: IAuthenticationMethodSettings;
        notificationsSettings: INotificationsSettings;
    };
    messageHistorySettings: {
        sentItemsSettings?: ISentItemsHistorySettingsValues | undefined;
        receivedItemsSettings?: IReceivedItemsHistorySettingsValues | undefined;
    };
    requestHistorySettings: IRequestHistorySettingsValues | undefined;
    messageSecuritySettings: IMessageSecuritySettings;
    dropOffSettings: IDropOffSettings;
    requestNotificationSettings: IRequestNotificationsSettings | undefined;
    savedMessageTemplateSettings: ISavedMessageTemplateSettings | undefined; 
    savedMessageDRTemplateSettings: ISavedMessageDRTemplateSettings | undefined;
}

export const initialState: IAdminSettingsStore = {
    isLoading: false,
    supportContactList: [],
    supportContactSettings: undefined,
    messageOptions: {
        retentionPeriodList: [],
        authenticationSettings: initialCompanyAuthenticationSettings,
        notificationsSettings: initialCompanyNotificationSettings,
    },
    messageHistorySettings: { sentItemsSettings: undefined, receivedItemsSettings: undefined },
    requestHistorySettings: undefined,
    messageSecuritySettings: initialMessageSecuritySettings,
    dropOffSettings: initialDropOffSettings,
    requestNotificationSettings : intialCompanyRequestNotificationSettings,
    savedMessageTemplateSettings: initialSavedMessageSettings,
    savedMessageDRTemplateSettings : initialSavedMessageDRSettings
};

export const reducer: Reducer<IAdminSettingsStore> = produce(
    (draft: IAdminSettingsStore = initialState, incomingAction: Action) => {
        const action = incomingAction as AdminSettingsActions.DispatchActions;
        switch (action.type) {
            case actionTypes.REQUEST_SUPPORT_CONTACT_LIST:
                draft.isLoading = true;
                return draft;
            case actionTypes.COMPLETE_SUPPORT_CONTACT_LIST:
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_SUPPORT_CONTACT_LIST:
                draft.supportContactList = action.payload;
                return draft;
            case actionTypes.REQUEST_PRIMARY_ADMIN:
                draft.isLoading = true;
                return draft;
            case actionTypes.COMPLETE_PRIMARY_ADMIN:
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_PRIMARY_ADMIN:
                draft.isLoading = false;
                return draft;
            case actionTypes.REQUEST_UPDATE_ADMIN_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.REQUEST_FETCH_ADMIN_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.COMPLETE_ADMIN_SETTINGS:
                draft.isLoading = false;
                return draft;
            case actionTypes.REQUEST_SUPPORT_CONTACT_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVE_SUPPORT_CONTACT_SETTINGS:
                draft.supportContactSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.REQUEST_ALL_RETENTION_PERIODS:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVE_ALL_RETENTION_PERIODS:
                draft.messageOptions.retentionPeriodList = action.retentionPeriods;
                draft.isLoading = false;
                return draft;
            case actionTypes.COMPLETE_UPDATE_ADMIN_SETTINGS:
                draft.isLoading = false;
                return draft;
            case actionTypes.REQUEST_RETENTION_SETTINGS:
                draft.isLoading = true;
                return draft;
            case actionTypes.RECEIVE_RETENTION_SETTINGS:
                draft.messageOptions.retentionSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_MESSAGE_HISTORY_SENT_ITEMS_SETTINGS:
                draft.messageHistorySettings.sentItemsSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_MESSAGE_HISTORY_RECEIVED_ITEMS_SETTINGS:
                draft.messageHistorySettings.receivedItemsSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_AUTHENTICATION_SETTINGS:
                draft.messageOptions.authenticationSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_MESSAGE_SECURITY_QUESTIONS_SETTINGS:
                draft.messageSecuritySettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_DROP_OFF_SETTINGS:
                draft.dropOffSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_NOTIFICATIONS_SETTINGS:
                draft.messageOptions.notificationsSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_REQUEST_HISTORY_SETTINGS:
                draft.requestHistorySettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_REQUESTNOTIFICATIONS_SETTINGS:
                draft.requestNotificationSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_SAVED_MESSAGE_MAIL_TEMPLATE_SETTINGS:
                draft.savedMessageTemplateSettings = action.payload;
                draft.isLoading = false;
                return draft;
            case actionTypes.RECEIVE_SAVED_MESSAGE_DR_MAIL_TEMPLATE_SETTINGS:
                draft.savedMessageDRTemplateSettings = action.payload;
                draft.isLoading = false;
                return draft;    
            default:
                return draft;
        }
    }
);
