export enum FolderType {
    UNKNOWN = -1,
    INBOX = 1,
    SENT = 2,
    SPAM = 3,
    ARCHIVE = 4,
    DRAFT = 5,
    DOCUMENTREQUEST = 6
}

export enum MessageType {
    UNKNOWN = 0,
    INBOX = 1,
    SENT = 2,
    DRAFT = 3,
}

export enum AuthenticationMethod {
    UNKNOWN = -1,
    ACCESSCODE = 0,
    LOGINPASSWORD = 1,
    QUESTIONANSWER = 2,
    SENDSECUREPDF = 3,
}

export enum MessageSource {
    UNKNOWN = -1,
    MAILBOX = 0,
    DROPOFF = 1,
    WEBADDIN = 2,
}

export enum MessageStatus {
    None = 0,
    DeletedBySender = 1,
    Canceled = 2,
    Pending = 3,
}

export enum MessageGroup {
    ReceivedMessage = 1,
    SentMessage = 2,
}

export enum MessageActions {
    Reply = 1,
    ReplyAll = 2,
    Forward = 3,
    New = 4,
    Draft = 5,
}

export enum MessageTab {
    Compose = 0,
    Options = 1,
    Preview = 2,
}

export enum RecipientType {
    ToRecipient = 1,
    CcRecipient = 2,
}

export enum SettingMode {
    USER = 0,
    COMPANY = 1,
    BOTH = 2,
}

export enum QuestionType {
    USER = SettingMode.USER,
    COMPANY = SettingMode.COMPANY,
    BOTH = SettingMode.BOTH,
}

export enum ValidationType {
    INVALID = 0,
    VALID = 1,
    WARNING = 2,
}

export enum MessageExplorerViewType {
    GridView = 0,
    ListView = 1,
}

export enum MessageViewType {
    Mail = 0,
    File = 1,
}

export enum RequestViewType {
    Request = 0,
    File = 1,
}

export enum ArchiveTabs{
    Message = 0,
    Request = 1
}

export enum FolderColumnName {
    FOLDERNAME = 1,
    EXPIRESDAYS = 2,
    CREATEDDATE = 3,
    DESCRIPTION = 4,
    SHAREDWITH = 5,
    OWNER = 6,
}
export enum TemplateColumnName {
    NAME = 1,
    DESCRIPTION = 2,
    CREATEDDATE = 3,
    MODIFIEDDATE = 4,
}

export enum SortOrder {
    ASC = 1,
    DESC,
}

export enum ArchiveFolderType {
    UNKNOWN = 0,
    MESSAGE = 1,
    FOLDER = 2,
}

export enum UserGroup {
    None = 0,
    Admin = 1,
    Staff = 2,
    Partner = 3,
    Signatures = 4,
}

export enum CompanySettingsName {
    SupportContactSettings = 1,
    SentMessageHistorySettings = 2,
    ReceivedMessageHistorySettings = 3,
    RetentionSettings = 4,
    DropOffSettings = 5,
    MessageSecurityQuestionSettings = 6,
    AuthenticationMethodSettings = 7,
    NotificationsSettings = 8,
    RequestHistorySettings = 9,
    RequestNotificationSettings = 10,
    SavedMessageTemplateSettings = 11,
    SavedMessageDRTemplateSettings = 12,
}

export enum FilterType {
    RIH = 1,
    SIH = 2,
    DRH = 3,
}

export enum LinkStatus {
    Active = 1,
    Cancelled = 2,
    Expired = 3,
}

export enum RequestStatus {
    Active = 1,
    Cancelled = 2,
    Expired = 3,
}

export enum MethodOfTransfer {
    DropOff = 1,
    PortalQA = 2,
    PortalAccessCode = 3,
    WebAddInQA = 4,
    WebAddInAccessCode = 5,
    FolderDropOff = 6
}

export enum DateType {
    All = 1,
    Last30Days = 2,
    Last12Months = 3,
    Custom = 4,
}

export enum DetailedStatus {
    NONE = 0,
    OPEN = 1,
    COMPLETED = 2,
    FAILED = 3,
    REOPEN = 4,
    EXPIRED = 5,
}
export enum ListingStatus {
    NONE = 0,
    PENDING_UPLOAD = 1,
    PARTIALLY_UPLOADED = 2,
    OVERDUE = 3,
    COMPLETED = 4,
    FAILED = 5,
}

export enum DropOffLinkType {
    COMPANY = 0,
    PERSONAL = 1,
    NONE = 2,
}

export enum ShareFolderTypes {
    NONE = 0,
    VIEW = 1,
    EDIT = 2,
    REMOVE = -1,
}

export enum RequestSource {
    Portal = 1,
    WebAddIn = 2,
}

export enum UpdateDropOffRecipientType {
    EnableRecipients = 1,
    DisableRecipients = 2,
    SetAsDefault = 3,
    RemoveAsDefault = 4,
}
export enum TabType {
    MessageOption = 0,
    AuthenticationQuestions,
    SupportContact,
    Configuration,
    User,
    MessageHistory,
    RequestHistory,
    DocumentCategory,
    RequestTemplate,
    MessageNotification,
    RequestNotification,
    MessageEmailTemplate,
    RequestsEmailTemplate,
    ArchiveMessage,
    ArchiveRequest,
    Messages,
    Requests,
    Onehub,
}

export enum DocumentRequestStatusHistoryReport {
    PendingUpload = 1,
    PartiallyUploaded = 2,
    Overdue = 3,
    Completed = 4,
    Expired = 5,
    Canceled = 6,
}

export enum DocumentCategorySortColumn {
    Name = 1,
    CreatedDate = 3,
}

export enum ShareFolderUserType {
    None = 0,
    User = 1,
    Group = 2
}

export enum TemplateType
{
    None =0,
    SecureMail = 1,
    DocumentRequest = 2
}

export enum TemplateSource
{
   None =0,
   SystemDefault =1,
   UserCreated = 2,
   AdminCreated =3
}

export enum NotifierTypes {
    Error,
    Success,
    Warning,
    Info,
    None
}

export enum FirmType {
    None = 0,
    Live = 1,
    Internal = 2,
}